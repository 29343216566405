export default [
  {
    path: "/dashboard/sams",
    name: "dashboard-lending",
    component: () => import("@/views/dashboard/sams/Sams.vue"),
    meta: {
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
  },
  {
    path: "/dashboard/ecommerce",
    name: "dashboard-ecommerce",
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
  },
];
