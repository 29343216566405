import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  payrolls: [
    {
      id: 1,
      // client: {
      client_no: '10155',
      client_name: 'SAFEGUARD PAYROLL STAFF',
      // },
      // employee: {
      employee_no: '38075',
      employee_name: 'Clamar Jr, Jaime',
      sss_no: '03-8934444-5',
      ph_no: '190887266720',
      hdmf_no: '105001654472',
      tax_no: '115-728-304',
      taxcode: 'ME3',
      birth_date: '1959-10-01',
      // },
      pay_period: '202201',
      pay_batch: '01',
      reg_hours: 120.00,
      reg_amount: 11330.00,
      ot_hours: 0.00,
      ot_amount: 0.00,
      nd_hours: 0.00,
      nd_amount: 0.00,
      rd_hours: 0.00,
      rd_amount: 0.00,
      rdot_hours: 0.00,
      rdot_amount: 0.00,
      rdnd_hours: 0.00,
      rdnd_amount: 0.00,
      sh_hours: 0.00,
      sh_amount: 0.00,
      shot_hours: 0.00,
      shot_amount: 0.00,
      shnd_hours: 0.00,
      shnd_amount: 0.00,
      lh_hours: 0.00,
      lh_amount: 0.00,
      lhot_hours: 0.00,
      lhot_amount: 0.00,
      lhnd_hours: 0.00,
      lhnd_amount: 0.00,
      earn01: 4155.00,
      earn02: 1000.00,
      total_earnings: 19345.00,
      sss_employee: 0.00,
      ph_employee: 0.00,
      hdmf_employee: 0.00,
      wTax: 1011.90,
      dedn01: 0.00,
      dedn02: 0.00,
      dedn03: 0.00,
      dedn04: 0.00,
      dedn05: 0.00,
      dedn06: 2141.79,
      dedn07: 1000.00,
      total_deductions: 4153.69,
      net_pay: 15191.31,
    },
    {
      id: 2,
      // client: {
      client_no: '10155',
      client_name: 'SAFEGUARD PAYROLL STAFF',
      // },
      // employee: {
      employee_no: '38075',
      employee_name: 'Delariarte, Adelita',
      sss_no: '03-9191124-8',
      ph_no: '190887268413',
      hdmf_no: '105001660389',
      tax_no: '115-728-387',
      taxcode: 'ME1',
      birth_date: '1967-10-20',
      // },
      pay_period: '202201',
      pay_batch: '01',
      reg_hours: 120.00,
      reg_amount: 11330.00,
      ot_hours: 0.00,
      ot_amount: 0.00,
      nd_hours: 0.00,
      nd_amount: 0.00,
      rd_hours: 0.00,
      rd_amount: 0.00,
      rdot_hours: 0.00,
      rdot_amount: 0.00,
      rdnd_hours: 0.00,
      rdnd_amount: 0.00,
      sh_hours: 0.00,
      sh_amount: 0.00,
      shot_hours: 0.00,
      shot_amount: 0.00,
      shnd_hours: 0.00,
      shnd_amount: 0.00,
      lh_hours: 0.00,
      lh_amount: 0.00,
      lhot_hours: 0.00,
      lhot_amount: 0.00,
      lhnd_hours: 0.00,
      lhnd_amount: 0.00,
      earn01: 4995.00,
      earn02: 1000.00,
      total_earnings: 17325.00,
      sss_employee: 787.50,
      ph_employee: 169.95,
      hdmf_employee: 100.00,
      wTax: 688.70,
      dedn01: 0.00,
      dedn02: 0.00,
      dedn03: 0.00,
      dedn04: 0.00,
      dedn05: 0.00,
      dedn06: 600.00,
      dedn07: 7803.75,
      total_deductions: 10149.90,
      net_pay: 7175.10,
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/payroll/payrolls').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1, sortBy = 'id', sortDesc = false, status = null } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  // const emp1 = '11011'
  const filteredData = data.payrolls.filter(
    payroll =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (payroll.pay_period.toLowerCase().includes(queryLowered) ||
      payroll.client_name.toLowerCase().includes(queryLowered) ||
      payroll.employee_no.toLowerCase().includes(queryLowered)) &&
    payroll.payrollStatus === (status || payroll.payrollStatus),
    // payroll.employee.employee_no === queryLowered,
    // payroll.employee.employee_no === (emp || payroll.employee.employee_no),
    // payroll.employee.employee_no.includes(queryLowered)
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      payrolls: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Single Payroll
// ------------------------------------------------
mock.onGet(/\/apps\/payroll\/payrolls\/\d+/).reply(config => {
  // Get event id from URL
  let payrollId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  payrollId = Number(payrollId)

  const payrollIndex = data.payrolls.findIndex(e => e.id === payrollId)
  const payroll = data.payrolls[payrollIndex]
  const responseData = {
    payroll,
    paymentDetails: {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    },
  }

  if (payroll) return [200, responseData]
  return [404]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/payroll/clients').reply(() => {
  const clients = data.payrolls.map(payroll => payroll.client)
  return [200, clients.slice(0, 5)]
})
