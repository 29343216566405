import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  employees: [
    {
      id: 1,
      fullName: 'Agustin, Zaldy',
      employee_no: '10011',
      employee_name: 'Agustin, Zaldy',
      country: 'Philippines',
      birth_date: '1963-01-01',
      gender: 'M',
      sss_no: '03-7317581-1',
      ph_no: '190887264353',
      hdmf_no: '105001651218',
      tax_no: '170-621-444',
      taxcode: 'ME4',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 2,
      fullName: 'Alcazar, Samuel',
      employee_no: '10012',
      employee_name: 'Alcazar, Samuel',
      country: 'Philippines',
      birth_date: '1972-06-13',
      gender: 'M',
      sss_no: '33-1674989-0',
      ph_no: '190887264485',
      hdmf_no: '105001651230',
      tax_no: '175-701-917',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 3,
      fullName: 'Cabatan, Hajji',
      employee_no: '10049',
      employee_name: 'Cabatan, Hajji',
      country: 'Philippines',
      birth_date: '1977-07-29',
      gender: 'M',
      sss_no: '33-2210436-4',
      ph_no: '020501648737',
      hdmf_no: '105001666283',
      tax_no: '226-909-104',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 4,
      fullName: 'Gabiana, Pablito',
      employee_no: '10096',
      employee_name: 'Gabiana, Pablito',
      country: 'Philippines',
      birth_date: '1973-06-15',
      gender: 'M',
      sss_no: '33-3549776-7',
      ph_no: '190524324885',
      hdmf_no: '105001666526',
      tax_no: '150-371-472',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 5,
      fullName: 'Dela Cruz, Danilo',
      employee_no: '10078',
      employee_name: 'Dela Cruz, Danilo',
      country: 'Philippines',
      birth_date: '1965-01-25',
      gender: 'M',
      sss_no: '33-0003223-8',
      ph_no: '190887267387',
      hdmf_no: '105001654303',
      tax_no: '128-875-045',
      taxcode: 'ME3',
      phone_no: '9474084744',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 6,
      fullName: 'Dagunan, Rey',
      employee_no: '10072',
      employee_name: 'Dagunan, Rey',
      country: 'Philippines',
      birth_date: '1979-03-17',
      gender: 'M',
      sss_no: '33-6710211-4',
      ph_no: '190902520132',
      hdmf_no: '105001808990',
      tax_no: '216-962-327',
      taxcode: 'ME1',
      phone_no: '0919557306',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 7,
      fullName: 'Daranciang, Antonio',
      employee_no: '10073',
      employee_name: 'Daranciang, Antonio',
      country: 'Philippines',
      birth_date: '1962-03-14',
      gender: 'M',
      sss_no: '01-0693043-0',
      ph_no: '190515852356',
      hdmf_no: '105001666403',
      tax_no: '128-875-038',
      taxcode: 'ME4',
      phone_no: '8441334',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 8,
      fullName: 'Delos Santos, Ronnie',
      employee_no: '10081',
      employee_name: 'Delos Santos, Ronnie',
      country: 'Philippines',
      birth_date: '1972-09-02',
      gender: 'M',
      sss_no: '10-0522991-1',
      ph_no: '190899552400',
      hdmf_no: '105001663017',
      tax_no: '208-560-298',
      taxcode: 'ME1',
      phone_no: '9268681312',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 9,
      fullName: 'Lacsa, Angelito',
      employee_no: '10109',
      employee_name: 'Lacsa, Angelito',
      country: 'Philippines',
      birth_date: '1963-11-19',
      gender: 'M',
      sss_no: '03-6429355-3',
      ph_no: '190887269460',
      hdmf_no: '105001666572',
      tax_no: '122-106-833',
      taxcode: 'ME4',
      phone_no: '778-9108',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 10,
      fullName: 'Mariano, Dennis',
      employee_no: '10127',
      employee_name: 'Mariano, Dennis',
      country: 'Philippines',
      birth_date: '1963-12-04',
      gender: 'M',
      sss_no: '03-7045908-0',
      ph_no: '030512652952',
      hdmf_no: '105001659310',
      tax_no: '226-357-762',
      taxcode: 'ME',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 11,
      fullName: 'Manongsong, Wadel',
      employee_no: '10123',
      employee_name: 'Manongsong, Wadel',
      country: 'Philippines',
      birth_date: '1973-12-20',
      gender: 'M',
      sss_no: '33-2308226-5',
      ph_no: '190887270280',
      hdmf_no: '105001698031',
      tax_no: '180-748-876',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 12,
      fullName: 'Gramonte Jr., Naldisto',
      employee_no: '10101',
      employee_name: 'Gramonte Jr., Naldisto',
      country: 'Philippines',
      birth_date: '1974-03-31',
      gender: 'M',
      sss_no: '33-3793153-5',
      ph_no: '020503491886',
      hdmf_no: '105001659899',
      tax_no: '913-353-466',
      taxcode: 'ME3',
      phone_no: '9368669956',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 13,
      fullName: 'Fernandez, Arsenio',
      employee_no: '10091',
      employee_name: 'Fernandez, Arsenio',
      country: 'Philippines',
      birth_date: '1963-09-03',
      gender: 'M',
      sss_no: '03-7209593-4',
      ph_no: '190523144700',
      hdmf_no: '105001658433',
      tax_no: '154-250-166',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 14,
      fullName: 'Salcedo, Jolly',
      employee_no: '10173',
      employee_name: 'Salcedo, Jolly',
      country: 'Philippines',
      birth_date: '1968-07-06',
      gender: 'M',
      sss_no: '03-9176962-3',
      ph_no: '190887272682',
      hdmf_no: '105001652285',
      tax_no: '170-623-366',
      taxcode: 'ME2',
      phone_no: '0920798184',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 15,
      fullName: 'Castro, Maria Rosario',
      employee_no: '00015',
      employee_name: 'Castro, Maria Rosario',
      country: 'Philippines',
      birth_date: '1965-10-07',
      gender: 'F',
      sss_no: '05-0423772-2',
      ph_no: '190887272097',
      hdmf_no: '105001793738',
      tax_no: '205-341-405',
      taxcode: 'S3',
      phone_no: '0919-72811',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 16,
      fullName: 'Perolino, Jonathan',
      employee_no: '10158',
      employee_name: 'Perolino, Jonathan',
      country: 'Philippines',
      birth_date: '1979-08-14',
      gender: 'M',
      sss_no: '33-2592157-3',
      ph_no: '190887271759',
      hdmf_no: '105001652139',
      tax_no: '909-048-172',
      taxcode: 'ME3',
      phone_no: '9275554322',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 17,
      fullName: 'Piedad, Romar',
      employee_no: '10159',
      employee_name: 'Piedad, Romar',
      country: 'Philippines',
      birth_date: '1981-01-28',
      gender: 'M',
      sss_no: '33-7463857-4',
      ph_no: '190895985620',
      hdmf_no: '105001660767',
      tax_no: '213-617-444',
      taxcode: 'ME3',
      phone_no: '09192075639',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 18,
      fullName: 'Pinkihan, Eric',
      employee_no: '10160',
      employee_name: 'Pinkihan, Eric',
      country: 'Philippines',
      birth_date: '1973-09-20',
      gender: 'M',
      sss_no: '33-2363926-5',
      ph_no: '190887271791',
      hdmf_no: '105001758356',
      tax_no: '174-952-418',
      taxcode: 'ME3',
      phone_no: '8813200',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 19,
      fullName: 'Quintos, Eduardo',
      employee_no: '10162',
      employee_name: 'Quintos, Eduardo',
      country: 'Philippines',
      birth_date: '1971-11-09',
      gender: 'M',
      sss_no: '33-1070722-3',
      ph_no: '190887272003',
      hdmf_no: '105001652185',
      tax_no: '173-784-215',
      taxcode: 'ME4',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 20,
      fullName: 'Dionisio, Eden',
      employee_no: '70178',
      employee_name: 'Dionisio, Eden',
      country: 'Philippines',
      birth_date: '1962-02-13',
      gender: 'M',
      sss_no: '03-5068734-8',
      ph_no: '190895984063',
      hdmf_no: '121115790686',
      tax_no: '251-483-965',
      taxcode: 'S2',
      phone_no: '09184613283',
      email: '',
      avatar: '',
      status: 'R',
    },
    {
      id: 21,
      fullName: 'Leyran, Ronaldo',
      employee_no: '10193',
      employee_name: 'Leyran, Ronaldo',
      country: 'Philippines',
      birth_date: '1972-01-15',
      gender: 'M',
      sss_no: '04-0744374-9',
      ph_no: '190887269630',
      hdmf_no: '121039450210',
      tax_no: '110-886-715',
      taxcode: 'ME2',
      phone_no: '9956395294',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 22,
      fullName: 'Ca¤ada, Darius',
      employee_no: '70062',
      employee_name: 'Ca¤ada, Darius',
      country: 'Philippines',
      birth_date: '1982-11-20',
      gender: 'M',
      sss_no: '33-7211541-3',
      ph_no: '020501806464',
      hdmf_no: '105001703626',
      tax_no: '225-606-569',
      taxcode: '',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 23,
      fullName: 'Nia Jr., Isidro',
      employee_no: '10144',
      employee_name: 'Nia Jr., Isidro',
      country: 'Philippines',
      birth_date: '1962-10-16',
      gender: 'M',
      sss_no: '03-9589682-2',
      ph_no: '190887270949',
      hdmf_no: '105001652004',
      tax_no: '122-107-485',
      taxcode: 'ME1',
      phone_no: '9287419',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 24,
      fullName: 'Obach, Christopher',
      employee_no: '10146',
      employee_name: 'Obach, Christopher',
      country: 'Philippines',
      birth_date: '1975-12-01',
      gender: 'M',
      sss_no: '08-1215112-1',
      ph_no: '190893131411',
      hdmf_no: '105001660409',
      tax_no: '218-577-036',
      taxcode: 'ME2',
      phone_no: '09204144106',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 25,
      fullName: 'Ondoy, Bernard',
      employee_no: '10148',
      employee_name: 'Ondoy, Bernard',
      country: 'Philippines',
      birth_date: '1970-08-08',
      gender: 'M',
      sss_no: '33-1637046-3',
      ph_no: '030512653177',
      hdmf_no: '121153769074',
      tax_no: '171-292-946',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 26,
      fullName: 'Tabisaura, Allan',
      employee_no: '70282',
      employee_name: 'Tabisaura, Allan',
      country: 'Philippines',
      birth_date: '1980-04-08',
      gender: 'M',
      sss_no: '33-6046453-2',
      ph_no: '190905396475',
      hdmf_no: '105001669291',
      tax_no: '216-354-640',
      taxcode: 'ME1',
      phone_no: '9051061720',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 27,
      fullName: 'Saberon, Rolando',
      employee_no: '70256',
      employee_name: 'Saberon, Rolando',
      country: 'Philippines',
      birth_date: '1966-05-02',
      gender: 'M',
      sss_no: '03-8024218-0',
      ph_no: '190515854634',
      hdmf_no: '105001731747',
      tax_no: '148-434-083',
      taxcode: 'ME3',
      phone_no: '2359475',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 28,
      fullName: 'Pagang, Sanny',
      employee_no: '00047',
      employee_name: 'Pagang, Sanny',
      country: 'Philippines',
      birth_date: '1968-12-21',
      gender: 'M',
      sss_no: '33-3447608-0',
      ph_no: '190891691215',
      hdmf_no: '105001713375',
      tax_no: '192-922-475',
      taxcode: 'ME3',
      phone_no: '9772000824',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 29,
      fullName: 'Entia, Herlito Hertz',
      employee_no: '00066',
      employee_name: 'Entia, Herlito Hertz',
      country: 'Philippines',
      birth_date: '1965-10-19',
      gender: 'M',
      sss_no: '03-8038530-6',
      ph_no: '190887267921',
      hdmf_no: '105001782183',
      tax_no: '147-619-416',
      taxcode: 'ME4',
      phone_no: '0919-5590654',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 30,
      fullName: 'Hermono, Jojie',
      employee_no: '10230',
      employee_name: 'Hermono, Jojie',
      country: 'Philippines',
      birth_date: '1973-01-05',
      gender: 'M',
      sss_no: '33-4753585-3',
      ph_no: '190894869872',
      hdmf_no: '105001660008',
      tax_no: '222-803-534',
      taxcode: 'ME3',
      phone_no: '9072959902',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 31,
      fullName: 'Sablada, Alexander',
      employee_no: '10566',
      employee_name: 'Sablada, Alexander',
      country: 'Philippines',
      birth_date: '1968-08-22',
      gender: 'M',
      sss_no: '33-3152230-6',
      ph_no: '190515854715',
      hdmf_no: '105001656792',
      tax_no: '187-853-752',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 32,
      fullName: 'Salinguhay, Rodel',
      employee_no: '10569',
      employee_name: 'Salinguhay, Rodel',
      country: 'Philippines',
      birth_date: '1975-07-30',
      gender: 'M',
      sss_no: '33-4592507-0',
      ph_no: '190893129484',
      hdmf_no: '105001717914',
      tax_no: '222-563-740',
      taxcode: 'ME4',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 33,
      fullName: 'Sampani, Roger',
      employee_no: '10573',
      employee_name: 'Sampani, Roger',
      country: 'Philippines',
      birth_date: '1974-09-23',
      gender: 'M',
      sss_no: '33-1901857-3',
      ph_no: '190887272755',
      hdmf_no: '105001656369',
      tax_no: '212-202-664',
      taxcode: 'ME2',
      phone_no: '9956219367',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 34,
      fullName: 'Santamena, Federico',
      employee_no: '10575',
      employee_name: 'Santamena, Federico',
      country: 'Philippines',
      birth_date: '1968-01-26',
      gender: 'M',
      sss_no: '03-9332543-4',
      ph_no: '190887272798',
      hdmf_no: '105001771282',
      tax_no: '122-090-312',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 35,
      fullName: 'Sevilla, Edgar',
      employee_no: '10585',
      employee_name: 'Sevilla, Edgar',
      country: 'Philippines',
      birth_date: '1974-09-22',
      gender: 'M',
      sss_no: '07-1469077-7',
      ph_no: '190887273034',
      hdmf_no: '105001652339',
      tax_no: '184-592-011',
      taxcode: 'ME4',
      phone_no: '9777629623',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 36,
      fullName: 'Casuple, Reynaldo',
      employee_no: '10719',
      employee_name: 'Casuple, Reynaldo',
      country: 'Philippines',
      birth_date: '1980-06-28',
      gender: 'M',
      sss_no: '33-7434433-4',
      ph_no: '190904541692',
      hdmf_no: '105001666337',
      tax_no: '219-579-542',
      taxcode: 'ME3',
      phone_no: '9263902046',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 37,
      fullName: 'Clamar Jr, Jaime',
      employee_no: '30003',
      employee_name: 'Clamar Jr, Jaime',
      country: 'Philippines',
      birth_date: '1959-10-01',
      gender: 'M',
      sss_no: '03-8934444-5',
      ph_no: '190887266720',
      hdmf_no: '105001654472',
      tax_no: '115-728-304',
      taxcode: 'ME3',
      phone_no: '7804513',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 38,
      fullName: 'Delariarte, Adelita',
      employee_no: '30004',
      employee_name: 'Delariarte, Adelita',
      country: 'Philippines',
      birth_date: '1967-10-20',
      gender: 'F',
      sss_no: '03-9191124-8',
      ph_no: '190887268413',
      hdmf_no: '105001660389',
      tax_no: '115-728-387',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 39,
      fullName: 'Ortile, Divina',
      employee_no: '30014',
      employee_name: 'Ortile, Divina',
      country: 'Philippines',
      birth_date: '1962-12-28',
      gender: 'F',
      sss_no: '03-7018480-5',
      ph_no: '190887271155',
      hdmf_no: '105001652049',
      tax_no: '115-728-554',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 40,
      fullName: 'Calma, Julie',
      employee_no: '30022',
      employee_name: 'Calma, Julie',
      country: 'Philippines',
      birth_date: '1968-01-02',
      gender: 'F',
      sss_no: '33-1126966-3',
      ph_no: '190887266259',
      hdmf_no: '105001653072',
      tax_no: '141-326-500',
      taxcode: 'ME2',
      phone_no: '9327333642',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 41,
      fullName: 'Del Rosario, Thelma',
      employee_no: '30023',
      employee_name: 'Del Rosario, Thelma',
      country: 'Philippines',
      birth_date: '1970-02-12',
      gender: ' ',
      sss_no: '33-1411104-8',
      ph_no: '190899552303',
      hdmf_no: '105001657868',
      tax_no: '151-600-024',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 42,
      fullName: 'Dela Cruz, Romeo',
      employee_no: '70344',
      employee_name: 'Dela Cruz, Romeo',
      country: 'Philippines',
      birth_date: '1975-06-06',
      gender: 'M',
      sss_no: '33-1833501-1',
      ph_no: '020500534398',
      hdmf_no: '105001766373',
      tax_no: '219-673-893',
      taxcode: 'ME1',
      phone_no: '09155168389',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 43,
      fullName: 'Albao, Romeo',
      employee_no: '10767',
      employee_name: 'Albao, Romeo',
      country: 'Philippines',
      birth_date: '1973-03-23',
      gender: 'M',
      sss_no: '33-1767371-6',
      ph_no: '190515138750',
      hdmf_no: '105001668981',
      tax_no: '913-710-353',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 44,
      fullName: 'Solano, Gilbert',
      employee_no: '30041',
      employee_name: 'Solano, Gilbert',
      country: 'Philippines',
      birth_date: '1968-05-25',
      gender: 'M',
      sss_no: '03-8654914-6',
      ph_no: '190515855495',
      hdmf_no: '105001665362',
      tax_no: '115-728-073',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 45,
      fullName: 'Pamplona, Jerry',
      employee_no: '00502',
      employee_name: 'Pamplona, Jerry',
      country: 'Philippines',
      birth_date: '1967-07-26',
      gender: 'M',
      sss_no: '33-0764698-6',
      ph_no: '190887271392',
      hdmf_no: '105001680627',
      tax_no: '270-852-281',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 46,
      fullName: 'Lugaris, Joey',
      employee_no: '11342',
      employee_name: 'Lugaris, Joey',
      country: 'Philippines',
      birth_date: '1983-01-05',
      gender: 'M',
      sss_no: '33-7811267-2',
      ph_no: '020503076161',
      hdmf_no: '105001742849',
      tax_no: '303-349-218',
      taxcode: 'HF',
      phone_no: '4171967',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 47,
      fullName: 'Notarte, Jesus',
      employee_no: '11344',
      employee_name: 'Notarte, Jesus',
      country: 'Philippines',
      birth_date: '1963-11-01',
      gender: 'M',
      sss_no: '03-8243772-8',
      ph_no: '190515853808',
      hdmf_no: '105001756925',
      tax_no: '122-107-494',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 48,
      fullName: 'Lumayaga, Janifer',
      employee_no: '13113',
      employee_name: 'Lumayaga, Janifer',
      country: 'Philippines',
      birth_date: '1981-09-10',
      gender: 'M',
      sss_no: '04-1234783-0',
      ph_no: '010500152457',
      hdmf_no: '105001668113',
      tax_no: '221-410-524',
      taxcode: 'ME1',
      phone_no: '9266839466',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 49,
      fullName: 'Ecle, Eduardo',
      employee_no: '38001',
      employee_name: 'Ecle, Eduardo',
      country: 'Philippines',
      birth_date: '1958-08-27',
      gender: ' ',
      sss_no: '03-5797703-5',
      ph_no: '190505744830',
      hdmf_no: '105001728426',
      tax_no: '154-154-931',
      taxcode: 'ME4',
      phone_no: '9053458237',
      email: '',
      avatar: '',
      status: 'N',
    },
    {
      id: 50,
      fullName: 'Sobrino, Thom',
      employee_no: '00529',
      employee_name: 'Sobrino, Thom',
      country: 'Philippines',
      birth_date: '1978-10-11',
      gender: 'M',
      sss_no: '07-3110504-1',
      ph_no: '020500366118',
      hdmf_no: '105001669067',
      tax_no: '248-133-851',
      taxcode: 'S',
      phone_no: '9263308993',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 51,
      fullName: 'Buhion, Jose Cesar',
      employee_no: '72334',
      employee_name: 'Buhion, Jose Cesar',
      country: 'Philippines',
      birth_date: '1971-01-21',
      gender: 'M',
      sss_no: '33-0413931-5',
      ph_no: '190893586249',
      hdmf_no: '105001745470',
      tax_no: '200-094-216',
      taxcode: 'ME4',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 52,
      fullName: 'Mugar, Paul Winston',
      employee_no: '72344',
      employee_name: 'Mugar, Paul Winston',
      country: 'Philippines',
      birth_date: '1983-03-13',
      gender: 'M',
      sss_no: '33-8401938-7',
      ph_no: '020503257735',
      hdmf_no: '105001692484',
      tax_no: '303-025-282',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'G',
    },
    {
      id: 53,
      fullName: 'Suyu, Roderic',
      employee_no: '12239',
      employee_name: 'Suyu, Roderic',
      country: 'Philippines',
      birth_date: '1978-02-10',
      gender: 'M',
      sss_no: '33-4055217-6',
      ph_no: '020503481740',
      hdmf_no: '105001672200',
      tax_no: '304-509-358',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 54,
      fullName: 'Costales, Jimmy',
      employee_no: '11603',
      employee_name: 'Costales, Jimmy',
      country: 'Philippines',
      birth_date: '1966-05-15',
      gender: 'M',
      sss_no: '03-9203256-6',
      ph_no: '190905382245',
      hdmf_no: '105001672456',
      tax_no: '173-440-771',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 55,
      fullName: 'Bariwa, Armando',
      employee_no: '51390',
      employee_name: 'Bariwa, Armando',
      country: 'Philippines',
      birth_date: '1982-05-31',
      gender: 'M',
      sss_no: '33-7082753-8',
      ph_no: '080505618339',
      hdmf_no: '121152865957',
      tax_no: '252-393-379',
      taxcode: 'HF',
      phone_no: '9229504014',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 56,
      fullName: 'Sarmiento Jr, Igmedio',
      employee_no: '72036',
      employee_name: 'Sarmiento Jr, Igmedio',
      country: 'Philippines',
      birth_date: '1973-01-17',
      gender: 'M',
      sss_no: '33-1065026-0',
      ph_no: '190514786309',
      hdmf_no: '105001692349',
      tax_no: '187-100-102',
      taxcode: 'ME1',
      phone_no: '9055212873',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 57,
      fullName: 'Viernes, Alexander',
      employee_no: '11972',
      employee_name: 'Viernes, Alexander',
      country: 'Philippines',
      birth_date: '1986-04-26',
      gender: 'M',
      sss_no: '34-0370081-0',
      ph_no: '010504617942',
      hdmf_no: '105001692416',
      tax_no: '251-568-612',
      taxcode: 'ME',
      phone_no: '9052467043',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 58,
      fullName: 'Tabalno, Edren',
      employee_no: '12002',
      employee_name: 'Tabalno, Edren',
      country: 'Philippines',
      birth_date: '1978-10-26',
      gender: 'M',
      sss_no: '33-5051404-9',
      ph_no: '080504014170',
      hdmf_no: '105001694024',
      tax_no: '227-512-992',
      taxcode: 'ME1',
      phone_no: '9152247397',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 59,
      fullName: 'Sampaga, Charlie',
      employee_no: '11994',
      employee_name: 'Sampaga, Charlie',
      country: 'Philippines',
      birth_date: '1976-11-19',
      gender: 'M',
      sss_no: '33-6827581-7',
      ph_no: '020501605868',
      hdmf_no: '105001694013',
      tax_no: '229-518-766',
      taxcode: 'ME2',
      phone_no: '9216295668',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 60,
      fullName: 'Operiano, Arman',
      employee_no: '12049',
      employee_name: 'Operiano, Arman',
      country: 'Philippines',
      birth_date: '1981-11-28',
      gender: 'M',
      sss_no: '05-0897137-2',
      ph_no: '020507588531',
      hdmf_no: '105001698053',
      tax_no: '256-993-135',
      taxcode: 'ME3',
      phone_no: '9205542441',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 61,
      fullName: 'Geronimo, Dennis',
      employee_no: '72414',
      employee_name: 'Geronimo, Dennis',
      country: 'Philippines',
      birth_date: '1975-01-06',
      gender: 'M',
      sss_no: '33-2294456-6',
      ph_no: '190523532050',
      hdmf_no: '105001703661',
      tax_no: '223-959-737',
      taxcode: 'ME1',
      phone_no: '9953319492',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 62,
      fullName: 'Manango, Felix',
      employee_no: '12348',
      employee_name: 'Manango, Felix',
      country: 'Philippines',
      birth_date: '1974-12-12',
      gender: 'M',
      sss_no: '33-1906131-3',
      ph_no: '030501141603',
      hdmf_no: '105001704425',
      tax_no: '182-642-858',
      taxcode: 'ME2',
      phone_no: '9284715014',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 63,
      fullName: 'Hechanova, Herman',
      employee_no: '72438',
      employee_name: 'Hechanova, Herman',
      country: 'Philippines',
      birth_date: '1977-11-25',
      gender: 'M',
      sss_no: '33-4260611-4',
      ph_no: '190524029999',
      hdmf_no: '105001809053',
      tax_no: '204-003-287',
      taxcode: 'ME4',
      phone_no: '9230852687',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 64,
      fullName: 'Villacarlos, Noel',
      employee_no: '12360',
      employee_name: 'Villacarlos, Noel',
      country: 'Philippines',
      birth_date: '1977-03-31',
      gender: 'M',
      sss_no: '33-7971710-2',
      ph_no: '020511181233',
      hdmf_no: '105001707444',
      tax_no: '259-620-894',
      taxcode: 'S1',
      phone_no: '9667196405',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 65,
      fullName: 'Tripon, Darwin',
      employee_no: '12374',
      employee_name: 'Tripon, Darwin',
      country: 'Philippines',
      birth_date: '1985-09-01',
      gender: 'M',
      sss_no: '34-0893357-2',
      ph_no: '020507537511',
      hdmf_no: '105001707422',
      tax_no: '260-740-267',
      taxcode: 'S',
      phone_no: '9264961849',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 66,
      fullName: 'Soriaso, Fedel',
      employee_no: '12379',
      employee_name: 'Soriaso, Fedel',
      country: 'Philippines',
      birth_date: '1976-04-24',
      gender: 'M',
      sss_no: '07-2107836-6',
      ph_no: '020504236928',
      hdmf_no: '105001708232',
      tax_no: '260-219-982',
      taxcode: 'S',
      phone_no: '9275708312',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 67,
      fullName: 'Oplas, Jonry',
      employee_no: '12392',
      employee_name: 'Oplas, Jonry',
      country: 'Philippines',
      birth_date: '1979-06-15',
      gender: 'M',
      sss_no: '07-1734452-5',
      ph_no: '110501350435',
      hdmf_no: '105001711921',
      tax_no: '246-249-112',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 68,
      fullName: 'Baldos, Jenemar',
      employee_no: '51537',
      employee_name: 'Baldos, Jenemar',
      country: 'Philippines',
      birth_date: '1988-03-03',
      gender: 'M',
      sss_no: '34-0657074-6',
      ph_no: '030512651522',
      hdmf_no: '121186139655',
      tax_no: '267-847-785',
      taxcode: 'ME1',
      phone_no: '9198480647',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 69,
      fullName: 'Medina, Khinghon',
      employee_no: '51538',
      employee_name: 'Medina, Khinghon',
      country: 'Philippines',
      birth_date: '1981-01-06',
      gender: 'M',
      sss_no: '34-1079484-8',
      ph_no: '100501754158',
      hdmf_no: '121166099148',
      tax_no: '271-376-510',
      taxcode: 'S',
      phone_no: '09391112268',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 70,
      fullName: 'Duran, Jerwin',
      employee_no: '12766',
      employee_name: 'Duran, Jerwin',
      country: 'Philippines',
      birth_date: '1978-12-18',
      gender: 'M',
      sss_no: '33-8247644-3',
      ph_no: '030503463640',
      hdmf_no: '108001468382',
      tax_no: '236-697-709',
      taxcode: 'ME1',
      phone_no: '9287677828',
      email: '',
      avatar: '',
      status: 'G',
    },
    {
      id: 71,
      fullName: 'Bernaldez, Dave',
      employee_no: '72996',
      employee_name: 'Bernaldez, Dave',
      country: 'Philippines',
      birth_date: '1983-01-24',
      gender: 'M',
      sss_no: '33-6972821-1',
      ph_no: '190905097753',
      hdmf_no: '109004739633',
      tax_no: '230-115-445',
      taxcode: 'ME',
      phone_no: '9397817574',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 72,
      fullName: 'Sumatra, Danilo',
      employee_no: '12793',
      employee_name: 'Sumatra, Danilo',
      country: 'Philippines',
      birth_date: '1980-07-26',
      gender: 'M',
      sss_no: '33-8844546-5',
      ph_no: '112018086363',
      hdmf_no: '121054981899',
      tax_no: '242-139-560',
      taxcode: 'ME1',
      phone_no: '9282529824',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 73,
      fullName: 'Japsay, Salvador',
      employee_no: '73084',
      employee_name: 'Japsay, Salvador',
      country: 'Philippines',
      birth_date: '1978-01-13',
      gender: 'M',
      sss_no: '33-2600903-8',
      ph_no: '190501982621',
      hdmf_no: '121121206436',
      tax_no: '201-832-079',
      taxcode: 'S',
      phone_no: '09295590827',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 74,
      fullName: 'Nanlabe, John Mark',
      employee_no: '12822',
      employee_name: 'Nanlabe, John Mark',
      country: 'Philippines',
      birth_date: '1986-09-21',
      gender: 'M',
      sss_no: '34-2359240-6',
      ph_no: '030512653134',
      hdmf_no: '121110427232',
      tax_no: '429-044-553',
      taxcode: 'S',
      phone_no: '9064556912',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 75,
      fullName: 'Protacio, Audry',
      employee_no: '12826',
      employee_name: 'Protacio, Audry',
      country: 'Philippines',
      birth_date: '1989-11-19',
      gender: 'M',
      sss_no: '34-2371633-4',
      ph_no: '020507537759',
      hdmf_no: '121134424834',
      tax_no: '403-769-105',
      taxcode: 'S',
      phone_no: '9302133198',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 76,
      fullName: 'Banguilan, Anthony',
      employee_no: '12835',
      employee_name: 'Banguilan, Anthony',
      country: 'Philippines',
      birth_date: '1984-04-23',
      gender: 'M',
      sss_no: '33-8276967-1',
      ph_no: '020510431325',
      hdmf_no: '121107038606',
      tax_no: '260-911-835',
      taxcode: 'S',
      phone_no: '9124724802',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 77,
      fullName: 'Osorio, Jose Benjie',
      employee_no: '12846',
      employee_name: 'Osorio, Jose Benjie',
      country: 'Philippines',
      birth_date: '1983-06-08',
      gender: 'M',
      sss_no: '33-7897874-8',
      ph_no: '020502665341',
      hdmf_no: '121081410061',
      tax_no: '230-713-357',
      taxcode: 'S',
      phone_no: '9284686569',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 78,
      fullName: 'Abao, Mary Ann',
      employee_no: '73170',
      employee_name: 'Abao, Mary Ann',
      country: 'Philippines',
      birth_date: '1984-09-16',
      gender: 'F',
      sss_no: '33-9625061-3',
      ph_no: '080505132185',
      hdmf_no: '121058462664',
      tax_no: '306-178-508',
      taxcode: 'ME1',
      phone_no: '9550629482',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 79,
      fullName: 'Viray, Gilbert',
      employee_no: '74879',
      employee_name: 'Viray, Gilbert',
      country: 'Philippines',
      birth_date: '1992-08-02',
      gender: 'M',
      sss_no: '34-2340547-0',
      ph_no: '210500782107',
      hdmf_no: '121158147029',
      tax_no: '423-783-472',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 80,
      fullName: 'Monteroyo, Sanny',
      employee_no: '13260',
      employee_name: 'Monteroyo, Sanny',
      country: 'Philippines',
      birth_date: '1980-11-10',
      gender: 'M',
      sss_no: '07-3415643-7',
      ph_no: '030512653096',
      hdmf_no: '121101883837',
      tax_no: '928-077-349',
      taxcode: 'S',
      phone_no: '930161120',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 81,
      fullName: 'Mago, Aziz',
      employee_no: '52131',
      employee_name: 'Mago, Aziz',
      country: 'Philippines',
      birth_date: '1985-12-19',
      gender: 'M',
      sss_no: '33-8792283-7',
      ph_no: '030501256281',
      hdmf_no: '106001987414',
      tax_no: '239-791-047',
      taxcode: 'ME1',
      phone_no: '9309517083',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 82,
      fullName: 'Avenido, Regie',
      employee_no: '74893',
      employee_name: 'Avenido, Regie',
      country: 'Philippines',
      birth_date: '1988-12-19',
      gender: 'M',
      sss_no: '34-3203801-6',
      ph_no: '030512651514',
      hdmf_no: '121166247519',
      tax_no: '422-900-351',
      taxcode: 'S',
      phone_no: '9453896503',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 83,
      fullName: 'Abanto, Rex',
      employee_no: '80843',
      employee_name: 'Abanto, Rex',
      country: 'Philippines',
      birth_date: '1980-09-07',
      gender: 'M',
      sss_no: '33-6501373-9',
      ph_no: '010502687439',
      hdmf_no: '102000048285',
      tax_no: '213-746-349',
      taxcode: 'ME3',
      phone_no: '9752313919',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 84,
      fullName: 'Abrew, Elma',
      employee_no: '13293',
      employee_name: 'Abrew, Elma',
      country: 'Philippines',
      birth_date: '1979-03-06',
      gender: 'F',
      sss_no: '33-2765180-9',
      ph_no: '030513290868',
      hdmf_no: '121115804171',
      tax_no: '213-691-495',
      taxcode: 'S3',
      phone_no: '9194097396',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 85,
      fullName: 'Bagos, Oliver',
      employee_no: '52172',
      employee_name: 'Bagos, Oliver',
      country: 'Philippines',
      birth_date: '1976-06-27',
      gender: 'M',
      sss_no: '04-0979158-3',
      ph_no: '190503782239',
      hdmf_no: '107001974127',
      tax_no: '224-488-790',
      taxcode: 'ME3',
      phone_no: '9198889688',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 86,
      fullName: 'Saco, Mycel',
      employee_no: '13299',
      employee_name: 'Saco, Mycel',
      country: 'Philippines',
      birth_date: '1989-05-01',
      gender: 'F',
      sss_no: '04-1917053-4',
      ph_no: '020505658958',
      hdmf_no: '121099032345',
      tax_no: '288-255-257',
      taxcode: 'S',
      phone_no: '9153565789',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 87,
      fullName: 'Oregano, Romer',
      employee_no: '52205',
      employee_name: 'Oregano, Romer',
      country: 'Philippines',
      birth_date: '1988-11-01',
      gender: 'M',
      sss_no: '34-2580672-1',
      ph_no: '112014512686',
      hdmf_no: '121172475320',
      tax_no: '413-777-806',
      taxcode: 'S',
      phone_no: '9092353477',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 88,
      fullName: 'Miranda, Skylarski',
      employee_no: '01434',
      employee_name: 'Miranda, Skylarski',
      country: 'Philippines',
      birth_date: '1980-04-27',
      gender: 'M',
      sss_no: '33-6226829-9',
      ph_no: '230002888683',
      hdmf_no: '121144315974',
      tax_no: '443-221-582',
      taxcode: 'ME2',
      phone_no: '9121549832',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 89,
      fullName: 'Navales Jr, Leonido',
      employee_no: '75059',
      employee_name: 'Navales Jr, Leonido',
      country: 'Philippines',
      birth_date: '1983-07-30',
      gender: 'M',
      sss_no: '10-0787429-6',
      ph_no: '120504575832',
      hdmf_no: '105001519598',
      tax_no: '238-130-500',
      taxcode: 'ME1',
      phone_no: '9398184192',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 90,
      fullName: 'Dacoycoy Jr, Camilo',
      employee_no: '01460',
      employee_name: 'Dacoycoy Jr, Camilo',
      country: 'Philippines',
      birth_date: '1978-12-19',
      gender: 'M',
      sss_no: '33-4539382-2',
      ph_no: '230030492663',
      hdmf_no: '121072934957',
      tax_no: '989-926-831',
      taxcode: 'ME3',
      phone_no: '9515910907',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 91,
      fullName: 'Estrebillo, Gilbert',
      employee_no: '01466',
      employee_name: 'Estrebillo, Gilbert',
      country: 'Philippines',
      birth_date: '1988-10-17',
      gender: 'M',
      sss_no: '34-0344250-5',
      ph_no: '100501586980',
      hdmf_no: '121141034852',
      tax_no: '431-387-302',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 92,
      fullName: 'Nellas, Robert',
      employee_no: '52348',
      employee_name: 'Nellas, Robert',
      country: 'Philippines',
      birth_date: '1976-06-28',
      gender: 'M',
      sss_no: '33-5658240-2',
      ph_no: '190527797814',
      hdmf_no: '107001134439',
      tax_no: '203-319-329',
      taxcode: 'ME2',
      phone_no: '9518752489',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 93,
      fullName: 'Menorca Jr, Julian',
      employee_no: '01527',
      employee_name: 'Menorca Jr, Julian',
      country: 'Philippines',
      birth_date: '1973-11-07',
      gender: 'M',
      sss_no: '33-1953549-6',
      ph_no: '190513194769',
      hdmf_no: '107001156610',
      tax_no: '201-062-007',
      taxcode: 'ME4',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 94,
      fullName: 'Millar, Richard',
      employee_no: '01528',
      employee_name: 'Millar, Richard',
      country: 'Philippines',
      birth_date: '1981-06-09',
      gender: 'M',
      sss_no: '04-1243684-8',
      ph_no: '080250941092',
      hdmf_no: '107001098715',
      tax_no: '221-947-979',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 95,
      fullName: 'Novencido, Eduardo',
      employee_no: '01529',
      employee_name: 'Novencido, Eduardo',
      country: 'Philippines',
      birth_date: '1966-12-19',
      gender: 'M',
      sss_no: '04-0647188-0',
      ph_no: '190252544716',
      hdmf_no: '121172472745',
      tax_no: '225-583-591',
      taxcode: 'ME3',
      phone_no: '9184523186',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 96,
      fullName: 'Resurreccion, Emil',
      employee_no: '13357',
      employee_name: 'Resurreccion, Emil',
      country: 'Philippines',
      birth_date: '1992-10-03',
      gender: 'M',
      sss_no: '34-3549638-3',
      ph_no: '030512653436',
      hdmf_no: '121107092063',
      tax_no: '433-508-786',
      taxcode: 'S',
      phone_no: '9554859312',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 97,
      fullName: 'Galicia, Hershey Lyne',
      employee_no: '38088',
      employee_name: 'Galicia, Hershey Lyne',
      country: 'Philippines',
      birth_date: '1991-01-10',
      gender: 'F',
      sss_no: '34-3283597-6',
      ph_no: '030513292984',
      hdmf_no: '121090956435',
      tax_no: '428-453-707',
      taxcode: 'S',
      phone_no: '09987451230',
      email: '',
      avatar: '',
      status: 'N',
    },
    {
      id: 98,
      fullName: 'Fajardo, John Mc Cartney',
      employee_no: '01569',
      employee_name: 'Fajardo, John Mc Cartney',
      country: 'Philippines',
      birth_date: '1981-08-02',
      gender: 'M',
      sss_no: '33-8247209-4',
      ph_no: '060251044948',
      hdmf_no: '121122151708',
      tax_no: '224-940-307',
      taxcode: 'HF',
      phone_no: '9158539312',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 99,
      fullName: 'Austria, Martin',
      employee_no: '75185',
      employee_name: 'Austria, Martin',
      country: 'Philippines',
      birth_date: '1976-11-11',
      gender: 'M',
      sss_no: '33-3825237-8',
      ph_no: '030512655293',
      hdmf_no: '121130817167',
      tax_no: '446-600-674',
      taxcode: 'ME2',
      phone_no: '9061583947',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 100,
      fullName: 'Mimay, Erwin',
      employee_no: '52543',
      employee_name: 'Mimay, Erwin',
      country: 'Philippines',
      birth_date: '1980-03-20',
      gender: 'M',
      sss_no: '05-0781416-8',
      ph_no: '100250466778',
      hdmf_no: '121058996163',
      tax_no: '922-799-759',
      taxcode: 'S3',
      phone_no: '9127261716',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 101,
      fullName: 'Gangawan, Aaron',
      employee_no: '01595',
      employee_name: 'Gangawan, Aaron',
      country: 'Philippines',
      birth_date: '1981-06-17',
      gender: 'M',
      sss_no: '05-0479982-0',
      ph_no: '102022264009',
      hdmf_no: '121157260889',
      tax_no: '412-788-972',
      taxcode: 'ME2',
      phone_no: '9514132559',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 102,
      fullName: 'Alondra, Jhomar',
      employee_no: '01596',
      employee_name: 'Alondra, Jhomar',
      country: 'Philippines',
      birth_date: '1988-03-08',
      gender: 'M',
      sss_no: '34-1824683-5',
      ph_no: '100501621891',
      hdmf_no: '121153658516',
      tax_no: '944-016-279',
      taxcode: 'ME1',
      phone_no: '9467480915',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 103,
      fullName: 'Batayo, Roseldo',
      employee_no: '13657',
      employee_name: 'Batayo, Roseldo',
      country: 'Philippines',
      birth_date: '1978-08-19',
      gender: 'M',
      sss_no: '33-7919260-4',
      ph_no: '030253459618',
      hdmf_no: '121207391526',
      tax_no: '222-929-065',
      taxcode: 'ME1',
      phone_no: '9279353125',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 104,
      fullName: 'Singson, Junrey',
      employee_no: '13676',
      employee_name: 'Singson, Junrey',
      country: 'Philippines',
      birth_date: '1984-02-11',
      gender: 'M',
      sss_no: '06-2514072-7',
      ph_no: '080508116873',
      hdmf_no: '121108388114',
      tax_no: '400-931-041',
      taxcode: 'ME',
      phone_no: '9293031804',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 105,
      fullName: 'Betco, Raffy Paulo',
      employee_no: '13677',
      employee_name: 'Betco, Raffy Paulo',
      country: 'Philippines',
      birth_date: '1991-05-19',
      gender: 'M',
      sss_no: '04-2204253-6',
      ph_no: '080511171648',
      hdmf_no: '121123423347',
      tax_no: '455-475-740',
      taxcode: 'S',
      phone_no: '9089720495',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 106,
      fullName: 'Ocop, John Rey',
      employee_no: '01682',
      employee_name: 'Ocop, John Rey',
      country: 'Philippines',
      birth_date: '1992-09-20',
      gender: 'M',
      sss_no: '34-4407947-4',
      ph_no: '130251774928',
      hdmf_no: '121209438661',
      tax_no: '474-474-351',
      taxcode: 'S',
      phone_no: '9303690611',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 107,
      fullName: 'Ramos, Herson',
      employee_no: '01813',
      employee_name: 'Ramos, Herson',
      country: 'Philippines',
      birth_date: '1972-09-12',
      gender: 'M',
      sss_no: '04-0844452-7',
      ph_no: '030512656990',
      hdmf_no: '121023846039',
      tax_no: '208-595-110',
      taxcode: 'S',
      phone_no: '9125817407',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 108,
      fullName: 'Dela Cruz, Leonardo',
      employee_no: '75914',
      employee_name: 'Dela Cruz, Leonardo',
      country: 'Philippines',
      birth_date: '1988-08-25',
      gender: 'M',
      sss_no: '01-2391387-0',
      ph_no: '062010619756',
      hdmf_no: '121131766045',
      tax_no: '470-160-522',
      taxcode: 'ME2',
      phone_no: '9056104433',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 109,
      fullName: 'Esternon, Melchor',
      employee_no: '01818',
      employee_name: 'Esternon, Melchor',
      country: 'Philippines',
      birth_date: '1988-07-08',
      gender: 'M',
      sss_no: '34-3131507-3',
      ph_no: '020509004278',
      hdmf_no: '121108688952',
      tax_no: '433-030-820',
      taxcode: 'ME2',
      phone_no: '9108319085',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 110,
      fullName: 'Sugoran, Roy',
      employee_no: '13832',
      employee_name: 'Sugoran, Roy',
      country: 'Philippines',
      birth_date: '1989-07-04',
      gender: 'M',
      sss_no: '34-3974222-4',
      ph_no: '030512653533',
      hdmf_no: '121204346442',
      tax_no: '468-925-078',
      taxcode: 'S',
      phone_no: '09186828614',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 111,
      fullName: 'Delda, Jesus',
      employee_no: '20504',
      employee_name: 'Delda, Jesus',
      country: 'Philippines',
      birth_date: '1994-03-31',
      gender: 'M',
      sss_no: '34-4028659-1',
      ph_no: '020510880227',
      hdmf_no: '121143321813',
      tax_no: '474-144-901',
      taxcode: 'S',
      phone_no: '9955530715',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 112,
      fullName: 'Albutra, Sammy',
      employee_no: '13840',
      employee_name: 'Albutra, Sammy',
      country: 'Philippines',
      birth_date: '1979-12-12',
      gender: 'M',
      sss_no: '09-1671235-6',
      ph_no: '162004115481',
      hdmf_no: '121071269163',
      tax_no: '948-983-915',
      taxcode: 'ME2',
      phone_no: '9474479756',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 113,
      fullName: 'Ocampo, Rodrigo',
      employee_no: '01838',
      employee_name: 'Ocampo, Rodrigo',
      country: 'Philippines',
      birth_date: '1966-05-10',
      gender: 'M',
      sss_no: '03-7534538-0',
      ph_no: '020500408260',
      hdmf_no: '121128977429',
      tax_no: '257-613-561',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 114,
      fullName: 'Gado, Jonard',
      employee_no: '01839',
      employee_name: 'Gado, Jonard',
      country: 'Philippines',
      birth_date: '1981-06-26',
      gender: 'M',
      sss_no: '33-5319552-0',
      ph_no: '030511579216',
      hdmf_no: '102004193384',
      tax_no: '238-592-187',
      taxcode: 'S',
      phone_no: '9203299307',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 115,
      fullName: 'Binayug, Michael',
      employee_no: '13841',
      employee_name: 'Binayug, Michael',
      country: 'Philippines',
      birth_date: '1987-08-14',
      gender: 'M',
      sss_no: '34-0404392-9',
      ph_no: '020261846099',
      hdmf_no: '121137943302',
      tax_no: '251-539-977',
      taxcode: 'S1',
      phone_no: '9615287695',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 116,
      fullName: 'Morada, Gerry',
      employee_no: '13843',
      employee_name: 'Morada, Gerry',
      country: 'Philippines',
      birth_date: '1980-07-20',
      gender: 'M',
      sss_no: '33-6178327-0',
      ph_no: '230013896734',
      hdmf_no: '121205586332',
      tax_no: '469-314-265',
      taxcode: 'ME3',
      phone_no: '9489626964',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 117,
      fullName: 'Undolag, Londren',
      employee_no: '01852',
      employee_name: 'Undolag, Londren',
      country: 'Philippines',
      birth_date: '1994-04-04',
      gender: 'M',
      sss_no: '34-4565919-4',
      ph_no: '020264860076',
      hdmf_no: '121143334823',
      tax_no: '467-880-207',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 118,
      fullName: 'Dalde Jr, William',
      employee_no: '76013',
      employee_name: 'Dalde Jr, William',
      country: 'Philippines',
      birth_date: '1993-02-22',
      gender: 'M',
      sss_no: '34-4860424-3',
      ph_no: '010257080795',
      hdmf_no: '121134507472',
      tax_no: '479-670-905',
      taxcode: 'S',
      phone_no: '9098953243',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 119,
      fullName: 'Munoz, Judelyn',
      employee_no: '20653',
      employee_name: 'Munoz, Judelyn',
      country: 'Philippines',
      birth_date: '1992-05-15',
      gender: 'F',
      sss_no: '01-2329393-0',
      ph_no: '052012486448',
      hdmf_no: '121119917726',
      tax_no: '474-564-010',
      taxcode: 'S',
      phone_no: '9077903175',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 120,
      fullName: 'Aligato, Raul',
      employee_no: '13916',
      employee_name: 'Aligato, Raul',
      country: 'Philippines',
      birth_date: '1974-09-09',
      gender: 'M',
      sss_no: '33-7560649-5',
      ph_no: '020501855759',
      hdmf_no: '106001918596',
      tax_no: '253-883-149',
      taxcode: 'ME3',
      phone_no: '9277565297',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 121,
      fullName: 'Bajaro, Albert',
      employee_no: '13920',
      employee_name: 'Bajaro, Albert',
      country: 'Philippines',
      birth_date: '1992-11-28',
      gender: 'M',
      sss_no: '05-1020212-3',
      ph_no: '102024304873',
      hdmf_no: '121049695716',
      tax_no: '943-732-205',
      taxcode: 'S',
      phone_no: '9087207199',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 122,
      fullName: 'Badang, Rickgel',
      employee_no: '13927',
      employee_name: 'Badang, Rickgel',
      country: 'Philippines',
      birth_date: '1991-04-07',
      gender: 'M',
      sss_no: '34-5165694-5',
      ph_no: '182010021384',
      hdmf_no: '121205537240',
      tax_no: '478-532-621',
      taxcode: 'S',
      phone_no: '9092809320',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 123,
      fullName: 'Edar, Christian',
      employee_no: '13931',
      employee_name: 'Edar, Christian',
      country: 'Philippines',
      birth_date: '1986-10-04',
      gender: 'M',
      sss_no: '05-0987340-8',
      ph_no: '010515962293',
      hdmf_no: '121067432199',
      tax_no: '476-353-788',
      taxcode: 'S',
      phone_no: '9104946670',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 124,
      fullName: 'Beguiras, Ronnie',
      employee_no: '01952',
      employee_name: 'Beguiras, Ronnie',
      country: 'Philippines',
      birth_date: '1978-08-29',
      gender: 'M',
      sss_no: '33-7696683-3',
      ph_no: '102019618606',
      hdmf_no: '121121755170',
      tax_no: '948-651-924',
      taxcode: 'ME2',
      phone_no: '9215629461',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 125,
      fullName: 'Rosales, Richard',
      employee_no: '20718',
      employee_name: 'Rosales, Richard',
      country: 'Philippines',
      birth_date: '1984-08-14',
      gender: 'M',
      sss_no: '33-8115039-1',
      ph_no: '190254708546',
      hdmf_no: '121115060479',
      tax_no: '293-481-960',
      taxcode: 'S1',
      phone_no: '9465500019',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 126,
      fullName: 'Nace, Aljimar',
      employee_no: '53003',
      employee_name: 'Nace, Aljimar',
      country: 'Philippines',
      birth_date: '1993-05-18',
      gender: 'M',
      sss_no: '34-4439975-6',
      ph_no: '102504325854',
      hdmf_no: '121147720153',
      tax_no: '327-508-298',
      taxcode: 'S',
      phone_no: '9217048590',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 127,
      fullName: 'Benghit, Jonnabel',
      employee_no: '14128',
      employee_name: 'Benghit, Jonnabel',
      country: 'Philippines',
      birth_date: '1981-10-20',
      gender: 'F',
      sss_no: '33-7457492-6',
      ph_no: '080250583358',
      hdmf_no: '105001692149',
      tax_no: '304-929-552',
      taxcode: 'S',
      phone_no: '9354094947',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 128,
      fullName: 'Veloso, Gunnar',
      employee_no: '02150',
      employee_name: 'Veloso, Gunnar',
      country: 'Philippines',
      birth_date: '1990-12-27',
      gender: 'M',
      sss_no: '34-2587564-2',
      ph_no: '080256561224',
      hdmf_no: '121188866242',
      tax_no: '336-196-757',
      taxcode: 'S2',
      phone_no: '9512208456',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 129,
      fullName: 'Tamayo Jr, Eduardo',
      employee_no: '14152',
      employee_name: 'Tamayo Jr, Eduardo',
      country: 'Philippines',
      birth_date: '1978-11-10',
      gender: 'M',
      sss_no: '33-5447821-7',
      ph_no: '190901157719',
      hdmf_no: '121189055552',
      tax_no: '258-417-100',
      taxcode: 'ME2',
      phone_no: '9294619564',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 130,
      fullName: 'Bacuyag, Mark',
      employee_no: '90210',
      employee_name: 'Bacuyag, Mark',
      country: 'Philippines',
      birth_date: '1990-12-03',
      gender: 'M',
      sss_no: '34-4626984-2',
      ph_no: '020265379576',
      hdmf_no: '121149239862',
      tax_no: '405-339-679',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 131,
      fullName: 'Senolos, Joeven',
      employee_no: '14154',
      employee_name: 'Senolos, Joeven',
      country: 'Philippines',
      birth_date: '1992-11-15',
      gender: 'M',
      sss_no: '34-6414815-2',
      ph_no: '132017462494',
      hdmf_no: '121189491273',
      tax_no: '495-178-849',
      taxcode: 'S',
      phone_no: '9264613842',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 132,
      fullName: 'Servande, Micheal Joe',
      employee_no: '14155',
      employee_name: 'Servande, Micheal Joe',
      country: 'Philippines',
      birth_date: '1993-01-18',
      gender: 'M',
      sss_no: '04-3596101-5',
      ph_no: '080514584323',
      hdmf_no: '121145769576',
      tax_no: '469-368-082',
      taxcode: 'S',
      phone_no: '9431381970',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 133,
      fullName: 'Arezalita, Jonathan',
      employee_no: '14157',
      employee_name: 'Arezalita, Jonathan',
      country: 'Philippines',
      birth_date: '1974-09-22',
      gender: 'M',
      sss_no: '33-3817647-0',
      ph_no: '012000256275',
      hdmf_no: '101004270283',
      tax_no: '906-377-604',
      taxcode: 'ME3',
      phone_no: '9393198885',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 134,
      fullName: 'Abutal, Nathandel',
      employee_no: '90225',
      employee_name: 'Abutal, Nathandel',
      country: 'Philippines',
      birth_date: '1991-07-15',
      gender: 'M',
      sss_no: '04-2296389-3',
      ph_no: '090252740057',
      hdmf_no: '121178697436',
      tax_no: '325-031-067',
      taxcode: 'S',
      phone_no: '9499752232',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 135,
      fullName: 'Repunte, Rodel',
      employee_no: '14159',
      employee_name: 'Repunte, Rodel',
      country: 'Philippines',
      birth_date: '1986-11-08',
      gender: 'M',
      sss_no: '34-2948928-3',
      ph_no: '020510133669',
      hdmf_no: '121128414155',
      tax_no: '253-256-084',
      taxcode: 'ME1',
      phone_no: '9232168161',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 136,
      fullName: 'Serde¤a, Charmie',
      employee_no: '14160',
      employee_name: 'Serde¤a, Charmie',
      country: 'Philippines',
      birth_date: '1977-04-15',
      gender: 'M',
      sss_no: '33-6484186-3',
      ph_no: '030504889803',
      hdmf_no: '108001968554',
      tax_no: '216-489-443',
      taxcode: 'ME1',
      phone_no: '9055182580',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 137,
      fullName: 'Lacerna, Cesar',
      employee_no: '02193',
      employee_name: 'Lacerna, Cesar',
      country: 'Philippines',
      birth_date: '1983-04-23',
      gender: 'M',
      sss_no: '04-1290150-6',
      ph_no: '080508798796',
      hdmf_no: '106001254612',
      tax_no: '327-055-031',
      taxcode: 'ME2',
      phone_no: '9969768766',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 138,
      fullName: 'Armenion, Dinah',
      employee_no: '14202',
      employee_name: 'Armenion, Dinah',
      country: 'Philippines',
      birth_date: '1985-08-06',
      gender: 'F',
      sss_no: '06-2753460-3',
      ph_no: '010514263979',
      hdmf_no: '121157564754',
      tax_no: '295-427-114',
      taxcode: 'S',
      phone_no: '9196736538',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 139,
      fullName: 'Perdigon, Alvin',
      employee_no: '14210',
      employee_name: 'Perdigon, Alvin',
      country: 'Philippines',
      birth_date: '1990-06-06',
      gender: 'M',
      sss_no: '34-1525433-8',
      ph_no: '020268615041',
      hdmf_no: '121204866265',
      tax_no: '335-916-770',
      taxcode: 'HF',
      phone_no: '9454469419',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 140,
      fullName: 'Magadia, Carlos',
      employee_no: '02200',
      employee_name: 'Magadia, Carlos',
      country: 'Philippines',
      birth_date: '1970-12-04',
      gender: 'M',
      sss_no: '04-3210963-4',
      ph_no: '030256373137',
      hdmf_no: '121108860357',
      tax_no: '235-049-827',
      taxcode: 'ME3',
      phone_no: '9092440331',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 141,
      fullName: 'Catindoy, Ricardo',
      employee_no: '21199',
      employee_name: 'Catindoy, Ricardo',
      country: 'Philippines',
      birth_date: '1987-01-10',
      gender: 'M',
      sss_no: '33-9096548-3',
      ph_no: '080508220980',
      hdmf_no: '121174400095',
      tax_no: '241-090-673',
      taxcode: 'ME1',
      phone_no: '09307824916',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 142,
      fullName: 'Ramirez, Michael',
      employee_no: '14212',
      employee_name: 'Ramirez, Michael',
      country: 'Philippines',
      birth_date: '1986-12-16',
      gender: 'M',
      sss_no: '34-3417689-5',
      ph_no: '102013600470',
      hdmf_no: '121116841939',
      tax_no: '476-290-745',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 143,
      fullName: 'Nidea, Edward',
      employee_no: '14214',
      employee_name: 'Nidea, Edward',
      country: 'Philippines',
      birth_date: '1979-03-18',
      gender: 'M',
      sss_no: '33-5244154-7',
      ph_no: '010255913366',
      hdmf_no: '121014945589',
      tax_no: '486-118-849',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 144,
      fullName: 'Halayahay, Aljon',
      employee_no: '14215',
      employee_name: 'Halayahay, Aljon',
      country: 'Philippines',
      birth_date: '1994-12-29',
      gender: 'M',
      sss_no: '34-5933968-8',
      ph_no: '030261655906',
      hdmf_no: '121204888434',
      tax_no: '335-673-330',
      taxcode: 'S',
      phone_no: '9467570580',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 145,
      fullName: 'Tabilas, Royland',
      employee_no: '14234',
      employee_name: 'Tabilas, Royland',
      country: 'Philippines',
      birth_date: '1993-03-07',
      gender: 'M',
      sss_no: '34-6461085-5',
      ph_no: '020268602373',
      hdmf_no: '121193005415',
      tax_no: '452-905-633',
      taxcode: 'S',
      phone_no: '9354314919',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 146,
      fullName: 'Yerro, Rosemarie',
      employee_no: '14266',
      employee_name: 'Yerro, Rosemarie',
      country: 'Philippines',
      birth_date: '1976-07-24',
      gender: ' ',
      sss_no: '33-3045315-9',
      ph_no: '190891754829',
      hdmf_no: '121066848033',
      tax_no: '166-701-055',
      taxcode: 'HF',
      phone_no: '9772678624',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 147,
      fullName: 'Rosales, Crestian',
      employee_no: '02217',
      employee_name: 'Rosales, Crestian',
      country: 'Philippines',
      birth_date: '1989-04-27',
      gender: ' ',
      sss_no: '34-2806683-0',
      ph_no: '102022919889',
      hdmf_no: '121149898797',
      tax_no: '           ',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 148,
      fullName: 'Calamba, Romel',
      employee_no: '02226',
      employee_name: 'Calamba, Romel',
      country: 'Philippines',
      birth_date: '1981-04-23',
      gender: 'M',
      sss_no: '04-3340276-7',
      ph_no: '190900603529',
      hdmf_no: '121038493173',
      tax_no: '218-984-717',
      taxcode: 'S',
      phone_no: '9478828836',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 149,
      fullName: 'Canilas Jr, Arnold',
      employee_no: '14304',
      employee_name: 'Canilas Jr, Arnold',
      country: 'Philippines',
      birth_date: '1994-02-26',
      gender: 'M',
      sss_no: '34-6537908-5',
      ph_no: '112522452419',
      hdmf_no: '121195141222',
      tax_no: '336-305-570',
      taxcode: 'S',
      phone_no: '9071203935',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 150,
      fullName: 'Mabansag, Rafael',
      employee_no: '14311',
      employee_name: 'Mabansag, Rafael',
      country: 'Philippines',
      birth_date: '1981-02-17',
      gender: 'M',
      sss_no: '06-2076607-0',
      ph_no: '132002974790',
      hdmf_no: '121149239061',
      tax_no: '301-608-000',
      taxcode: 'ME3',
      phone_no: '9997234544',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 151,
      fullName: 'Oto, Dante',
      employee_no: '02310',
      employee_name: 'Oto, Dante',
      country: 'Philippines',
      birth_date: '1982-08-15',
      gender: 'M',
      sss_no: '10-0993210-7',
      ph_no: '140500742999',
      hdmf_no: '121088160882',
      tax_no: '410-305-769',
      taxcode: 'S',
      phone_no: '9055998499',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 152,
      fullName: 'Perida, Miler',
      employee_no: '14447',
      employee_name: 'Perida, Miler',
      country: 'Philippines',
      birth_date: '1993-10-15',
      gender: 'M',
      sss_no: '34-5367483-1',
      ph_no: '010521827918',
      hdmf_no: '121205884550',
      tax_no: '706-737-560',
      taxcode: 'HF',
      phone_no: '9386559058',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 153,
      fullName: 'Lazaro, Jefrey',
      employee_no: '14448',
      employee_name: 'Lazaro, Jefrey',
      country: 'Philippines',
      birth_date: '1992-03-03',
      gender: 'M',
      sss_no: '34-1450158-7',
      ph_no: '030507091772',
      hdmf_no: '121099557652',
      tax_no: '403-832-140',
      taxcode: 'ME2',
      phone_no: '9474991997',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 154,
      fullName: 'Chua, Joshua',
      employee_no: '02323',
      employee_name: 'Chua, Joshua',
      country: 'Philippines',
      birth_date: '1995-10-14',
      gender: 'M',
      sss_no: '34-3507126-1',
      ph_no: '010518936196',
      hdmf_no: '121119166786',
      tax_no: '449-893-157',
      taxcode: 'S',
      phone_no: '9099267417',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 155,
      fullName: 'Magno, Romer Ryan',
      employee_no: '14455',
      employee_name: 'Magno, Romer Ryan',
      country: 'Philippines',
      birth_date: '1993-06-26',
      gender: 'M',
      sss_no: '34-4105220-3',
      ph_no: '112525087622',
      hdmf_no: '121205438637',
      tax_no: '707-545-509',
      taxcode: 'S',
      phone_no: '9460754982',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 156,
      fullName: 'Sacal, Rolly',
      employee_no: '14459',
      employee_name: 'Sacal, Rolly',
      country: 'Philippines',
      birth_date: '1980-04-17',
      gender: 'M',
      sss_no: '34-0018352-4',
      ph_no: '030507609283',
      hdmf_no: '121102084482',
      tax_no: '281-906-371',
      taxcode: 'S2',
      phone_no: '9954654205',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 157,
      fullName: 'Abe, Mark Anthony',
      employee_no: '21555',
      employee_name: 'Abe, Mark Anthony',
      country: 'Philippines',
      birth_date: '1988-08-24',
      gender: 'M',
      sss_no: '33-9711493-6',
      ph_no: '030507469120',
      hdmf_no: '121039370447',
      tax_no: '311-756-003',
      taxcode: 'ME1',
      phone_no: '9050834482',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 158,
      fullName: 'Merano, Kelvin',
      employee_no: '21560',
      employee_name: 'Merano, Kelvin',
      country: 'Philippines',
      birth_date: '1986-06-14',
      gender: 'M',
      sss_no: '09-2637824-3',
      ph_no: '020259111510',
      hdmf_no: '121082107669',
      tax_no: '936-630-237',
      taxcode: 'ME1',
      phone_no: '9673681699',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 159,
      fullName: 'Labis, Allan',
      employee_no: '02491',
      employee_name: 'Labis, Allan',
      country: 'Philippines',
      birth_date: '1993-04-25',
      gender: 'M',
      sss_no: '05-1349660-6',
      ph_no: '100252710439',
      hdmf_no: '121196301080',
      tax_no: '           ',
      taxcode: 'S',
      phone_no: '9108893618',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 160,
      fullName: 'Cawaling, Donald Jay',
      employee_no: '14531',
      employee_name: 'Cawaling, Donald Jay',
      country: 'Philippines',
      birth_date: '1993-09-28',
      gender: 'M',
      sss_no: '34-3905795-1',
      ph_no: '220001885030',
      hdmf_no: '121092360159',
      tax_no: '343-874-920',
      taxcode: 'S',
      phone_no: '9463515211',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 161,
      fullName: 'Sicojan, Maileen',
      employee_no: '14532',
      employee_name: 'Sicojan, Maileen',
      country: 'Philippines',
      birth_date: '1985-04-22',
      gender: 'F',
      sss_no: '34-0163615-1',
      ph_no: '030507544068',
      hdmf_no: '108001864892',
      tax_no: '343-876-630',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 162,
      fullName: 'Banayat Jr, Crispin',
      employee_no: '90481',
      employee_name: 'Banayat Jr, Crispin',
      country: 'Philippines',
      birth_date: '1980-12-02',
      gender: 'M',
      sss_no: '34-0392376-5',
      ph_no: '030503230689',
      hdmf_no: '121178451906',
      tax_no: '257-013-255',
      taxcode: 'ME2',
      phone_no: '9667819467',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 163,
      fullName: 'Garpa, Jarry',
      employee_no: '53576',
      employee_name: 'Garpa, Jarry',
      country: 'Philippines',
      birth_date: '1995-01-04',
      gender: 'M',
      sss_no: '34-5267117-8',
      ph_no: '030261654098',
      hdmf_no: '121200192867',
      tax_no: '339-791-148',
      taxcode: 'S',
      phone_no: '9078531157',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 164,
      fullName: 'Billones, Dario',
      employee_no: '14546',
      employee_name: 'Billones, Dario',
      country: 'Philippines',
      birth_date: '1979-10-10',
      gender: 'M',
      sss_no: '33-5411141-9',
      ph_no: '030502960160',
      hdmf_no: '121105317776',
      tax_no: '304-038-227',
      taxcode: 'S1',
      phone_no: '9101187980',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 165,
      fullName: 'Armada, Ariel',
      employee_no: '14549',
      employee_name: 'Armada, Ariel',
      country: 'Philippines',
      birth_date: '1989-04-05',
      gender: 'M',
      sss_no: '34-2414930-8',
      ph_no: '020265218408',
      hdmf_no: '121147415706',
      tax_no: '404-204-668',
      taxcode: 'ME1',
      phone_no: '9367162133',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 166,
      fullName: 'Erica, Reneboy',
      employee_no: '14550',
      employee_name: 'Erica, Reneboy',
      country: 'Philippines',
      birth_date: '1988-10-22',
      gender: 'M',
      sss_no: '34-3271351-9',
      ph_no: '090503764147',
      hdmf_no: '121128082214',
      tax_no: '315-110-399',
      taxcode: 'S',
      phone_no: '9663394883',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 167,
      fullName: 'A¤ola, Rommel',
      employee_no: '14551',
      employee_name: 'A¤ola, Rommel',
      country: 'Philippines',
      birth_date: '1991-10-25',
      gender: 'M',
      sss_no: '34-1995002-3',
      ph_no: '020509781908',
      hdmf_no: '121133495474',
      tax_no: '344-752-996',
      taxcode: 'ME1',
      phone_no: '9552916282',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 168,
      fullName: 'De Alca Jr, Wenceslao',
      employee_no: '14576',
      employee_name: 'De Alca Jr, Wenceslao',
      country: 'Philippines',
      birth_date: '1987-09-10',
      gender: 'M',
      sss_no: '34-7287356-1',
      ph_no: '132018326934',
      hdmf_no: '121216513150',
      tax_no: '344-761-597',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 169,
      fullName: 'Tolentino, Emelito',
      employee_no: '14577',
      employee_name: 'Tolentino, Emelito',
      country: 'Philippines',
      birth_date: '1976-05-17',
      gender: 'M',
      sss_no: '33-1389810-0',
      ph_no: '080262986768',
      hdmf_no: '121124447615',
      tax_no: '455-163-189',
      taxcode: 'ME4',
      phone_no: '9558709771',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 170,
      fullName: 'Bohol, Nelvin',
      employee_no: '76545',
      employee_name: 'Bohol, Nelvin',
      country: 'Philippines',
      birth_date: '1990-07-01',
      gender: 'M',
      sss_no: '34-3279613-8',
      ph_no: '220001451375',
      hdmf_no: '121110818491',
      tax_no: '435-012-875',
      taxcode: 'S2',
      phone_no: '9307193777',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 171,
      fullName: 'Rico, Heinrich',
      employee_no: '21640',
      employee_name: 'Rico, Heinrich',
      country: 'Philippines',
      birth_date: '1985-10-25',
      gender: 'M',
      sss_no: '34-7306429-8',
      ph_no: '010262468564',
      hdmf_no: '121219217519',
      tax_no: '344-684-591',
      taxcode: 'S',
      phone_no: '09461836726',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 172,
      fullName: 'Felomino Jr, Patricio',
      employee_no: '14580',
      employee_name: 'Felomino Jr, Patricio',
      country: 'Philippines',
      birth_date: '1992-08-16',
      gender: 'M',
      sss_no: '34-5518989-0',
      ph_no: '142509588332',
      hdmf_no: '121159493385',
      tax_no: '330-199-813',
      taxcode: 'S',
      phone_no: '9663980244',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 173,
      fullName: 'Dumandan, Bong',
      employee_no: '02530',
      employee_name: 'Dumandan, Bong',
      country: 'Philippines',
      birth_date: '1978-06-05',
      gender: 'M',
      sss_no: '33-7917454-1',
      ph_no: '010514787080',
      hdmf_no: '105002280522',
      tax_no: '304-573-364',
      taxcode: 'S',
      phone_no: '9212484936',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 174,
      fullName: 'Velasco, Rey',
      employee_no: '21665',
      employee_name: 'Velasco, Rey',
      country: 'Philippines',
      birth_date: '1965-11-05',
      gender: 'M',
      sss_no: '01-0721423-6',
      ph_no: '050256589637',
      hdmf_no: '121213984462',
      tax_no: '767-655-795',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 175,
      fullName: 'Mayagma, Jenus',
      employee_no: '14603',
      employee_name: 'Mayagma, Jenus',
      country: 'Philippines',
      birth_date: '1987-05-12',
      gender: 'M',
      sss_no: '07-2692918-0',
      ph_no: '010259835807',
      hdmf_no: '121176171210',
      tax_no: '425-990-817',
      taxcode: 'S',
      phone_no: '9354938891',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 176,
      fullName: 'Amadeo, Ricky',
      employee_no: '14688',
      employee_name: 'Amadeo, Ricky',
      country: 'Philippines',
      birth_date: '1975-12-09',
      gender: 'M',
      sss_no: '33-5959738-4',
      ph_no: '190887498591',
      hdmf_no: '121024865692',
      tax_no: '219-653-921',
      taxcode: 'ME3',
      phone_no: '9453053693',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 177,
      fullName: 'Palma, Albert Jason',
      employee_no: '14689',
      employee_name: 'Palma, Albert Jason',
      country: 'Philippines',
      birth_date: '1989-03-24',
      gender: 'M',
      sss_no: '04-2230750-1',
      ph_no: '030255146625',
      hdmf_no: '121107820758',
      tax_no: '423-021-101',
      taxcode: 'S',
      phone_no: '9152335636',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 178,
      fullName: 'Calag V, Jose',
      employee_no: '14690',
      employee_name: 'Calag V, Jose',
      country: 'Philippines',
      birth_date: '1978-10-04',
      gender: 'M',
      sss_no: '33-5180871-2',
      ph_no: '010262609303',
      hdmf_no: '121219284841',
      tax_no: '345-793-560',
      taxcode: 'ME3',
      phone_no: '9156797646',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 179,
      fullName: 'Montalan, Froilan',
      employee_no: '14695',
      employee_name: 'Montalan, Froilan',
      country: 'Philippines',
      birth_date: '1980-04-21',
      gender: 'M',
      sss_no: '33-6382356-5',
      ph_no: '082008996942',
      hdmf_no: '121099425779',
      tax_no: '228-906-407',
      taxcode: 'HF',
      phone_no: '9568852637',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 180,
      fullName: 'Bingil, Richard',
      employee_no: '14697',
      employee_name: 'Bingil, Richard',
      country: 'Philippines',
      birth_date: '1981-11-04',
      gender: ' ',
      sss_no: '33-9050755-3',
      ph_no: '080507141157',
      hdmf_no: '107001604262',
      tax_no: '934-846-237',
      taxcode: 'HF',
      phone_no: '9507073320',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 181,
      fullName: 'Furton Jr, Manuel',
      employee_no: '14711',
      employee_name: 'Furton Jr, Manuel',
      country: 'Philippines',
      birth_date: '1991-12-19',
      gender: 'M',
      sss_no: '04-2406853-4',
      ph_no: '102020402293',
      hdmf_no: '121013399778',
      tax_no: '345-654-433',
      taxcode: 'S',
      phone_no: '9462064072',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 182,
      fullName: 'Llaneta, Arnold',
      employee_no: '14706',
      employee_name: 'Llaneta, Arnold',
      country: 'Philippines',
      birth_date: '1978-04-04',
      gender: 'M',
      sss_no: '33-2990792-0',
      ph_no: '230024587393',
      hdmf_no: '121045268389',
      tax_no: '900-153-103',
      taxcode: 'HF',
      phone_no: '9054834088',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 183,
      fullName: 'Castro, Eduard',
      employee_no: '14708',
      employee_name: 'Castro, Eduard',
      country: 'Philippines',
      birth_date: '1985-01-27',
      gender: 'M',
      sss_no: '33-8960711-8',
      ph_no: '020502414225',
      hdmf_no: '121095378610',
      tax_no: '235-575-939',
      taxcode: 'HF',
      phone_no: '9121390751',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 184,
      fullName: 'Tiongco, Jorge',
      employee_no: '14709',
      employee_name: 'Tiongco, Jorge',
      country: 'Philippines',
      birth_date: '1979-12-26',
      gender: 'M',
      sss_no: '33-4803798-1',
      ph_no: '190514507493',
      hdmf_no: '121023542218',
      tax_no: '223-532-744',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 185,
      fullName: 'Edulzora, Jessie',
      employee_no: '14718',
      employee_name: 'Edulzora, Jessie',
      country: 'Philippines',
      birth_date: '1980-04-08',
      gender: 'M',
      sss_no: '33-6098634-0',
      ph_no: '030503150340',
      hdmf_no: '101000518047',
      tax_no: '212-347-530',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 186,
      fullName: 'Redondo, Erwin',
      employee_no: '14719',
      employee_name: 'Redondo, Erwin',
      country: 'Philippines',
      birth_date: '1979-04-25',
      gender: 'M',
      sss_no: '33-5368092-5',
      ph_no: '190903156760',
      hdmf_no: '121053704727',
      tax_no: '218-523-146',
      taxcode: 'HF',
      phone_no: '9122832044',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 187,
      fullName: 'Apolonio, Nilo',
      employee_no: '14720',
      employee_name: 'Apolonio, Nilo',
      country: 'Philippines',
      birth_date: '1973-09-10',
      gender: 'M',
      sss_no: '33-2327380-9',
      ph_no: '192001710962',
      hdmf_no: '121096895224',
      tax_no: '264-051-374',
      taxcode: 'HF',
      phone_no: '9289694858',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 188,
      fullName: 'Navarro, Alona',
      employee_no: '14721',
      employee_name: 'Navarro, Alona',
      country: 'Philippines',
      birth_date: '1988-09-26',
      gender: 'F',
      sss_no: '07-2692391-1',
      ph_no: '010514430441',
      hdmf_no: '121075822756',
      tax_no: '465-783-343',
      taxcode: 'HF',
      phone_no: '9106543723',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 189,
      fullName: 'Alfabete, Ariel',
      employee_no: '14722',
      employee_name: 'Alfabete, Ariel',
      country: 'Philippines',
      birth_date: '1977-03-09',
      gender: 'M',
      sss_no: '33-4114362-3',
      ph_no: '030505568830',
      hdmf_no: '121096620199',
      tax_no: '278-305-357',
      taxcode: 'S',
      phone_no: '9308145476',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 190,
      fullName: 'Nobela, Arthur',
      employee_no: '14723',
      employee_name: 'Nobela, Arthur',
      country: 'Philippines',
      birth_date: '1983-01-19',
      gender: 'M',
      sss_no: '33-9793231-2',
      ph_no: '030502393738',
      hdmf_no: '121096695633',
      tax_no: '271-133-917',
      taxcode: 'HF',
      phone_no: '9982711305',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 191,
      fullName: 'Magpantay, Rhenan',
      employee_no: '14776',
      employee_name: 'Magpantay, Rhenan',
      country: 'Philippines',
      birth_date: '1989-07-22',
      gender: 'M',
      sss_no: '34-1270067-8',
      ph_no: '020508358793',
      hdmf_no: '121198999557',
      tax_no: '270-715-336',
      taxcode: 'HF',
      phone_no: '9301704628',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 192,
      fullName: 'Barbacion, Jonathan',
      employee_no: '14779',
      employee_name: 'Barbacion, Jonathan',
      country: 'Philippines',
      birth_date: '1973-11-24',
      gender: 'M',
      sss_no: '33-3533875-8',
      ph_no: '190892666567',
      hdmf_no: '105001567445',
      tax_no: '200-723-522',
      taxcode: 'ME2',
      phone_no: '9475760083',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 193,
      fullName: 'Gentica, Ricky',
      employee_no: '14783',
      employee_name: 'Gentica, Ricky',
      country: 'Philippines',
      birth_date: '1986-09-09',
      gender: 'M',
      sss_no: '33-9109928-0',
      ph_no: '030506655087',
      hdmf_no: '121089845401',
      tax_no: '270-137-708',
      taxcode: 'HF',
      phone_no: '9753894990',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 194,
      fullName: 'Torregosa, Edna',
      employee_no: '14784',
      employee_name: 'Torregosa, Edna',
      country: 'Philippines',
      birth_date: '1978-01-22',
      gender: 'F',
      sss_no: '33-7314190-7',
      ph_no: '190892716106',
      hdmf_no: '121012278217',
      tax_no: '231-404-573',
      taxcode: 'HF',
      phone_no: '9555983802',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 195,
      fullName: 'Buen, Marilou',
      employee_no: '14786',
      employee_name: 'Buen, Marilou',
      country: 'Philippines',
      birth_date: '1975-10-08',
      gender: 'F',
      sss_no: '04-3108290-1',
      ph_no: '080507641516',
      hdmf_no: '147000374765',
      tax_no: '243-831-885',
      taxcode: 'ME3',
      phone_no: '9102105892',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 196,
      fullName: 'Malate, Nilo',
      employee_no: '14789',
      employee_name: 'Malate, Nilo',
      country: 'Philippines',
      birth_date: '1994-07-03',
      gender: 'M',
      sss_no: '05-1083174-3',
      ph_no: '020269253305',
      hdmf_no: '121202542603',
      tax_no: '341-325-941',
      taxcode: 'HF',
      phone_no: '9552909954',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 197,
      fullName: 'Antivo, Wilfredo',
      employee_no: '14790',
      employee_name: 'Antivo, Wilfredo',
      country: 'Philippines',
      birth_date: '1984-05-11',
      gender: 'M',
      sss_no: '34-1846937-9',
      ph_no: '010514117603',
      hdmf_no: '121033613021',
      tax_no: '465-631-767',
      taxcode: 'HF',
      phone_no: '9208270399',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 198,
      fullName: 'Ecat, Marry Jean',
      employee_no: '21717',
      employee_name: 'Ecat, Marry Jean',
      country: 'Philippines',
      birth_date: '1996-04-03',
      gender: 'F',
      sss_no: '08-2466396-6',
      ph_no: '152024742495',
      hdmf_no: '121117291909',
      tax_no: '345-633-294',
      taxcode: 'S',
      phone_no: '9552915913',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 199,
      fullName: 'Marapoc, Sunny Boy',
      employee_no: '14794',
      employee_name: 'Marapoc, Sunny Boy',
      country: 'Philippines',
      birth_date: '1990-06-06',
      gender: ' ',
      sss_no: '34-1141978-2',
      ph_no: '020507161791',
      hdmf_no: '121058235204',
      tax_no: '709-130-464',
      taxcode: 'HF',
      phone_no: '9095486760',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 200,
      fullName: 'Suazo, Jocel',
      employee_no: '14796',
      employee_name: 'Suazo, Jocel',
      country: 'Philippines',
      birth_date: '1986-03-16',
      gender: ' ',
      sss_no: '09-2884084-7',
      ph_no: '160503767071',
      hdmf_no: '121190447196',
      tax_no: '482-745-962',
      taxcode: 'HF',
      phone_no: '9934566897',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 201,
      fullName: 'Alvarez, Samelito',
      employee_no: '14797',
      employee_name: 'Alvarez, Samelito',
      country: 'Philippines',
      birth_date: '1977-02-20',
      gender: 'M',
      sss_no: '06-1752439-3',
      ph_no: '020503374804',
      hdmf_no: '121054515583',
      tax_no: '306-273-620',
      taxcode: 'ME3',
      phone_no: '9150637040',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 202,
      fullName: 'Hilbue¤a, Richard',
      employee_no: '14799',
      employee_name: 'Hilbue¤a, Richard',
      country: 'Philippines',
      birth_date: '1973-08-05',
      gender: 'M',
      sss_no: '34-1153907-3',
      ph_no: '230024118857',
      hdmf_no: '121172015132',
      tax_no: '908-050-546',
      taxcode: 'HF',
      phone_no: '9124319634',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 203,
      fullName: 'Suanque, Rumyr',
      employee_no: '14802',
      employee_name: 'Suanque, Rumyr',
      country: 'Philippines',
      birth_date: '1990-05-30',
      gender: 'M',
      sss_no: '07-2578805-0',
      ph_no: '110504663450',
      hdmf_no: '121086490814',
      tax_no: '335-587-867',
      taxcode: 'S',
      phone_no: '9084769273',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 204,
      fullName: 'Mi¤on, Laurence',
      employee_no: '14940',
      employee_name: 'Mi¤on, Laurence',
      country: 'Philippines',
      birth_date: '1981-12-20',
      gender: 'M',
      sss_no: '34-0246152-9',
      ph_no: '030505002241',
      hdmf_no: '121057812468',
      tax_no: '278-895-642',
      taxcode: 'ME2',
      phone_no: '9508261172',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 205,
      fullName: 'De Guia Jr, Feliciano',
      employee_no: '14988',
      employee_name: 'De Guia Jr, Feliciano',
      country: 'Philippines',
      birth_date: '1976-11-24',
      gender: 'M',
      sss_no: '33-2307355-9',
      ph_no: '230025246808',
      hdmf_no: '121126583842',
      tax_no: '916-771-538',
      taxcode: 'S',
      phone_no: '9068377828',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 206,
      fullName: 'Sayo, Emerson',
      employee_no: '90577',
      employee_name: 'Sayo, Emerson',
      country: 'Philippines',
      birth_date: '1988-07-19',
      gender: 'M',
      sss_no: '34-0733968-5',
      ph_no: '080511251560',
      hdmf_no: '121016399209',
      tax_no: '310-199-873',
      taxcode: 'HF',
      phone_no: '9958997987',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 207,
      fullName: 'Astronomo, Jerome',
      employee_no: '15021',
      employee_name: 'Astronomo, Jerome',
      country: 'Philippines',
      birth_date: '1987-06-19',
      gender: 'M',
      sss_no: '33-9522171-1',
      ph_no: '020255230981',
      hdmf_no: '121146511570',
      tax_no: '250-698-957',
      taxcode: 'S',
      phone_no: '9217050765',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 208,
      fullName: 'Cowak, Efren',
      employee_no: '76846',
      employee_name: 'Cowak, Efren',
      country: 'Philippines',
      birth_date: '1991-11-17',
      gender: 'M',
      sss_no: '34-7690583-3',
      ph_no: '142016317070',
      hdmf_no: '121228814175',
      tax_no: '349-058-271',
      taxcode: 'HF',
      phone_no: '9751046968',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 209,
      fullName: 'Calag, Jocris',
      employee_no: '15036',
      employee_name: 'Calag, Jocris',
      country: 'Philippines',
      birth_date: '1984-01-11',
      gender: 'M',
      sss_no: '34-2639825-4',
      ph_no: '020260286618',
      hdmf_no: '121229995162',
      tax_no: '293-337-832',
      taxcode: 'S1',
      phone_no: '9661359423',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 210,
      fullName: 'Siano, Raffy',
      employee_no: '15037',
      employee_name: 'Siano, Raffy',
      country: 'Philippines',
      birth_date: '1997-06-16',
      gender: 'M',
      sss_no: '34-7690979-0',
      ph_no: '020270999752',
      hdmf_no: '121228903774',
      tax_no: '349-444-503',
      taxcode: 'S',
      phone_no: '9268380733',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 211,
      fullName: 'Gonzales, Richard',
      employee_no: '15041',
      employee_name: 'Gonzales, Richard',
      country: 'Philippines',
      birth_date: '1990-06-14',
      gender: 'M',
      sss_no: '34-4543579-0',
      ph_no: '212003691982',
      hdmf_no: '121232302188',
      tax_no: '429-634-194',
      taxcode: 'S',
      phone_no: '9655778720',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 212,
      fullName: 'Gomez, Mary Grace',
      employee_no: '02784',
      employee_name: 'Gomez, Mary Grace',
      country: 'Philippines',
      birth_date: '1975-08-19',
      gender: 'F',
      sss_no: '33-2074563-1',
      ph_no: '090501809356',
      hdmf_no: '121021076359',
      tax_no: '305-772-642',
      taxcode: 'S2',
      phone_no: '9292386467',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 213,
      fullName: 'Belonio, Richard',
      employee_no: '21906',
      employee_name: 'Belonio, Richard',
      country: 'Philippines',
      birth_date: '1991-11-28',
      gender: 'M',
      sss_no: '34-2846198-5',
      ph_no: '010514645863',
      hdmf_no: '121063680140',
      tax_no: '417-550-730',
      taxcode: 'ME1',
      phone_no: '09554030469',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 214,
      fullName: 'Angeles, Jan Paulo',
      employee_no: '15047',
      employee_name: 'Angeles, Jan Paulo',
      country: 'Philippines',
      birth_date: '1995-01-05',
      gender: 'M',
      sss_no: '05-1384539-6',
      ph_no: '102027679509',
      hdmf_no: '121216622567',
      tax_no: '977-301-247',
      taxcode: 'S',
      phone_no: '9351592556',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 215,
      fullName: 'Garcia Jr, Reynaldo',
      employee_no: '15142',
      employee_name: 'Garcia Jr, Reynaldo',
      country: 'Philippines',
      birth_date: '1997-01-21',
      gender: 'M',
      sss_no: '34-6349002-1',
      ph_no: '010260377502',
      hdmf_no: '121185385819',
      tax_no: '730-137-974',
      taxcode: 'HF',
      phone_no: '9484114268',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 216,
      fullName: 'Belotindos, Noel',
      employee_no: '15143',
      employee_name: 'Belotindos, Noel',
      country: 'Philippines',
      birth_date: '1994-01-08',
      gender: 'M',
      sss_no: '34-6037890-0',
      ph_no: '080261305740',
      hdmf_no: '121172745104',
      tax_no: '720-627-906',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 217,
      fullName: 'Geollegue, Jessie',
      employee_no: '15069',
      employee_name: 'Geollegue, Jessie',
      country: 'Philippines',
      birth_date: '1984-12-26',
      gender: 'M',
      sss_no: '34-1233395-1',
      ph_no: '020261123607',
      hdmf_no: '121106407763',
      tax_no: '741-695-477',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 218,
      fullName: 'Clores, Domingo',
      employee_no: '15157',
      employee_name: 'Clores, Domingo',
      country: 'Philippines',
      birth_date: '1976-01-28',
      gender: 'M',
      sss_no: '33-3505050-2',
      ph_no: '080503294838',
      hdmf_no: '121059272987',
      tax_no: '908-775-653',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 219,
      fullName: 'Robeso, Lorenzo',
      employee_no: '15160',
      employee_name: 'Robeso, Lorenzo',
      country: 'Philippines',
      birth_date: '1993-08-09',
      gender: 'M',
      sss_no: '34-2286901-7',
      ph_no: '102018835360',
      hdmf_no: '121220900519',
      tax_no: '351-484-450',
      taxcode: 'ME1',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 220,
      fullName: 'Mesa, Erly',
      employee_no: '15161',
      employee_name: 'Mesa, Erly',
      country: 'Philippines',
      birth_date: '1987-04-15',
      gender: 'M',
      sss_no: '34-1369933-5',
      ph_no: '010514902238',
      hdmf_no: '121138736673',
      tax_no: '351-485-322',
      taxcode: 'ME2',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 221,
      fullName: 'Salazar, Estepani',
      employee_no: '76905',
      employee_name: 'Salazar, Estepani',
      country: 'Philippines',
      birth_date: '1990-10-14',
      gender: 'F',
      sss_no: '34-1845242-5',
      ph_no: '220000781568',
      hdmf_no: '121084223392',
      tax_no: '732-729-424',
      taxcode: 'S2',
      phone_no: '9087812700',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 222,
      fullName: 'Mari¤as, ChristianCristo',
      employee_no: '15168',
      employee_name: 'Mari¤as, ChristianCristo',
      country: 'Philippines',
      birth_date: '1984-03-02',
      gender: 'M',
      sss_no: '02-2627337-8',
      ph_no: '052007120362',
      hdmf_no: '121096666170',
      tax_no: '733-439-120',
      taxcode: 'ME2',
      phone_no: '9124783234',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 223,
      fullName: 'De Loyola, Wilson',
      employee_no: '15173',
      employee_name: 'De Loyola, Wilson',
      country: 'Philippines',
      birth_date: '1982-07-31',
      gender: 'M',
      sss_no: '05-0735006-2',
      ph_no: '102008670681',
      hdmf_no: '121142177863',
      tax_no: '           ',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 224,
      fullName: 'Alonzo, Mark Anthony',
      employee_no: '15174',
      employee_name: 'Alonzo, Mark Anthony',
      country: 'Philippines',
      birth_date: '1991-07-09',
      gender: 'M',
      sss_no: '34-2126746-1',
      ph_no: '102529657517',
      hdmf_no: '121232623028',
      tax_no: '718-903-019',
      taxcode: 'ME',
      phone_no: '9354663928',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 225,
      fullName: 'Romero, Richard',
      employee_no: '15207',
      employee_name: 'Romero, Richard',
      country: 'Philippines',
      birth_date: '1988-05-23',
      gender: 'M',
      sss_no: '34-0619901-7',
      ph_no: '030503652346',
      hdmf_no: '121131767479',
      tax_no: '255-097-289',
      taxcode: 'S',
      phone_no: '9052593014',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 226,
      fullName: 'Suba, Francisco',
      employee_no: '15224',
      employee_name: 'Suba, Francisco',
      country: 'Philippines',
      birth_date: '1973-10-04',
      gender: 'M',
      sss_no: '33-5592139-4',
      ph_no: '190503262030',
      hdmf_no: '107001290873',
      tax_no: '205-401-346',
      taxcode: 'ME2',
      phone_no: '9779419991',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 227,
      fullName: 'Vinasoy, Rostom',
      employee_no: '22046',
      employee_name: 'Vinasoy, Rostom',
      country: 'Philippines',
      birth_date: '1993-01-11',
      gender: 'M',
      sss_no: '02-3570931-2',
      ph_no: '070508986996',
      hdmf_no: '121227215377',
      tax_no: '355-493-184',
      taxcode: 'S',
      phone_no: '9365518536',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 228,
      fullName: 'Buzarang, John Michael',
      employee_no: '15320',
      employee_name: 'Buzarang, John Michael',
      country: 'Philippines',
      birth_date: '1997-06-27',
      gender: 'M',
      sss_no: '34-6465888-8',
      ph_no: '080262286731',
      hdmf_no: '121189924835',
      tax_no: '767-949-902',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 229,
      fullName: 'Icnad Jr, Jaime',
      employee_no: '15322',
      employee_name: 'Icnad Jr, Jaime',
      country: 'Philippines',
      birth_date: '1994-03-14',
      gender: 'M',
      sss_no: '34-8279511-8',
      ph_no: '010261762167',
      hdmf_no: '121249075242',
      tax_no: '508-910-592',
      taxcode: 'S',
      phone_no: '9299710793',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 230,
      fullName: 'Binarao, Ariel',
      employee_no: '15324',
      employee_name: 'Binarao, Ariel',
      country: 'Philippines',
      birth_date: '1996-05-09',
      gender: 'M',
      sss_no: '34-7975644-5',
      ph_no: '090256582464',
      hdmf_no: '121237419073',
      tax_no: '730-988-064',
      taxcode: 'ME2',
      phone_no: '9386396647',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 231,
      fullName: 'Limbang, Mary Grace',
      employee_no: '90783',
      employee_name: 'Limbang, Mary Grace',
      country: 'Philippines',
      birth_date: '1982-12-19',
      gender: 'F',
      sss_no: '33-7940552-4',
      ph_no: '080510961483',
      hdmf_no: '121007049217',
      tax_no: '417-753-911',
      taxcode: 'S',
      phone_no: '9260565886',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 232,
      fullName: 'Aligan, Ricky Jr.',
      employee_no: '15326',
      employee_name: 'Aligan, Ricky Jr.',
      country: 'Philippines',
      birth_date: '1996-08-20',
      gender: 'M',
      sss_no: '34-8198405-6',
      ph_no: '030264182812',
      hdmf_no: '121244796209',
      tax_no: '745-521-949',
      taxcode: 'S',
      phone_no: '9098309612',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 233,
      fullName: 'Motol, Joey',
      employee_no: '15331',
      employee_name: 'Motol, Joey',
      country: 'Philippines',
      birth_date: '1981-06-01',
      gender: 'M',
      sss_no: '33-7343740-4',
      ph_no: '092005100811',
      hdmf_no: '108002208364',
      tax_no: '467-073-682',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 234,
      fullName: 'Quilates, Rodrigo',
      employee_no: '15354',
      employee_name: 'Quilates, Rodrigo',
      country: 'Philippines',
      birth_date: '1996-12-03',
      gender: 'M',
      sss_no: '02-4019713-9',
      ph_no: '050255689131',
      hdmf_no: '121195247884',
      tax_no: '357-172-255',
      taxcode: 'S',
      phone_no: '9120279192',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 235,
      fullName: 'Beri¤a, Ni¤o',
      employee_no: '15360',
      employee_name: 'Beri¤a, Ni¤o',
      country: 'Philippines',
      birth_date: '1982-01-12',
      gender: 'M',
      sss_no: '33-6604013-8',
      ph_no: '010252305098',
      hdmf_no: '121080272448',
      tax_no: '237-973-966',
      taxcode: 'S',
      phone_no: '9386381404',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 236,
      fullName: 'Cantor, Jason',
      employee_no: '77136',
      employee_name: 'Cantor, Jason',
      country: 'Philippines',
      birth_date: '1980-06-26',
      gender: 'M',
      sss_no: '04-1306630-1',
      ph_no: '090502691502',
      hdmf_no: '121032272289',
      tax_no: '469-436-937',
      taxcode: 'S',
      phone_no: '9105238840',
      email: '',
      avatar: '',
      status: 'R',
    },
    {
      id: 237,
      fullName: 'Baligod, Winston',
      employee_no: '15362',
      employee_name: 'Baligod, Winston',
      country: 'Philippines',
      birth_date: '1978-04-05',
      gender: 'M',
      sss_no: '33-4342771-6',
      ph_no: '190250192596',
      hdmf_no: '121066479045',
      tax_no: '440-428-050',
      taxcode: 'S1',
      phone_no: '9063465562',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 238,
      fullName: 'Magallon, Rommel',
      employee_no: '15363',
      employee_name: 'Magallon, Rommel',
      country: 'Philippines',
      birth_date: '1996-04-14',
      gender: 'M',
      sss_no: '34-8317173-1',
      ph_no: '030264467507',
      hdmf_no: '121252324311',
      tax_no: '745-360-917',
      taxcode: 'S',
      phone_no: '9208367022',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 239,
      fullName: 'Famucol, Ryan',
      employee_no: '15383',
      employee_name: 'Famucol, Ryan',
      country: 'Philippines',
      birth_date: '1985-03-04',
      gender: 'M',
      sss_no: '07-3187735-3',
      ph_no: '110504872521',
      hdmf_no: '121097073511',
      tax_no: '416-216-396',
      taxcode: 'ME2',
      phone_no: '9954991058',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 240,
      fullName: 'Damas, Rowena',
      employee_no: '15387',
      employee_name: 'Damas, Rowena',
      country: 'Philippines',
      birth_date: '1994-09-10',
      gender: 'F',
      sss_no: '34-4489248-6',
      ph_no: '032004617095',
      hdmf_no: '121252570131',
      tax_no: '368-287-693',
      taxcode: 'S',
      phone_no: '9675135499',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 241,
      fullName: 'Posas, Frederick',
      employee_no: '15390',
      employee_name: 'Posas, Frederick',
      country: 'Philippines',
      birth_date: '1979-05-29',
      gender: 'M',
      sss_no: '04-3382700-9',
      ph_no: '190900646325',
      hdmf_no: '147000914612',
      tax_no: '207-380-474',
      taxcode: 'ME',
      phone_no: '9959477522',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 242,
      fullName: 'Anorio, John Mar',
      employee_no: '38216',
      employee_name: 'Anorio, John Mar',
      country: 'Philippines',
      birth_date: '1990-01-02',
      gender: 'M',
      sss_no: '34-2270653-6',
      ph_no: '020507537643',
      hdmf_no: '121097753426',
      tax_no: '402-912-222',
      taxcode: 'S',
      phone_no: '9164364623',
      email: '',
      avatar: '',
      status: 'N',
    },
    {
      id: 243,
      fullName: 'Bigtas, Jimmy Boy',
      employee_no: '15392',
      employee_name: 'Bigtas, Jimmy Boy',
      country: 'Philippines',
      birth_date: '1990-01-15',
      gender: 'M',
      sss_no: '34-7078298-4',
      ph_no: '010261919066',
      hdmf_no: '121217307140',
      tax_no: '509-122-392',
      taxcode: 'ME',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 244,
      fullName: 'Senolos, Gilbert',
      employee_no: '15394',
      employee_name: 'Senolos, Gilbert',
      country: 'Philippines',
      birth_date: '1989-01-28',
      gender: 'M',
      sss_no: '34-2833081-2',
      ph_no: '010513128806',
      hdmf_no: '121099787229',
      tax_no: '477-541-489',
      taxcode: 'ME2',
      phone_no: '9162203514',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 245,
      fullName: 'Licot, Sonny',
      employee_no: '90821',
      employee_name: 'Licot, Sonny',
      country: 'Philippines',
      birth_date: '1992-09-29',
      gender: 'M',
      sss_no: '06-3096588-7',
      ph_no: '120509830008',
      hdmf_no: '121171368180',
      tax_no: '446-951-701',
      taxcode: 'ME1',
      phone_no: '9974261125',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 246,
      fullName: 'Burong, Antonio',
      employee_no: '90822',
      employee_name: 'Burong, Antonio',
      country: 'Philippines',
      birth_date: '1993-02-25',
      gender: 'M',
      sss_no: '34-6615658-2',
      ph_no: '010261948546',
      hdmf_no: '121194679838',
      tax_no: '747-918-376',
      taxcode: 'S',
      phone_no: '9307172202',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 247,
      fullName: 'Sarmiento, Elmie',
      employee_no: '15405',
      employee_name: 'Sarmiento, Elmie',
      country: 'Philippines',
      birth_date: '1985-11-02',
      gender: 'F',
      sss_no: '34-6745276-4',
      ph_no: '080262865200',
      hdmf_no: '121198659744',
      tax_no: '257-962-512',
      taxcode: 'S2',
      phone_no: '09469043434',
      email: '',
      avatar: '',
      status: 'R',
    },
    {
      id: 248,
      fullName: 'Tugade, Merben',
      employee_no: '15408',
      employee_name: 'Tugade, Merben',
      country: 'Philippines',
      birth_date: '1981-05-03',
      gender: 'M',
      sss_no: '33-7263146-3',
      ph_no: '190892158170',
      hdmf_no: '121075196973',
      tax_no: '216-275-013',
      taxcode: 'ME3',
      phone_no: '9451509261',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 249,
      fullName: 'O¤asa, Mark Anthony',
      employee_no: '15410',
      employee_name: 'O¤asa, Mark Anthony',
      country: 'Philippines',
      birth_date: '1992-12-20',
      gender: 'M',
      sss_no: '07-3840438-9',
      ph_no: '112029165869',
      hdmf_no: '121236259725',
      tax_no: '358-489-440',
      taxcode: 'S',
      phone_no: '9301396964',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 250,
      fullName: 'Sabaduquia, Raffy',
      employee_no: '77205',
      employee_name: 'Sabaduquia, Raffy',
      country: 'Philippines',
      birth_date: '1972-10-19',
      gender: 'M',
      sss_no: '08-0919280-5',
      ph_no: '190523720329',
      hdmf_no: '121094656767',
      tax_no: '903-706-183',
      taxcode: 'ME4',
      phone_no: '9302255392',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 251,
      fullName: 'Campaner, Rey',
      employee_no: '15436',
      employee_name: 'Campaner, Rey',
      country: 'Philippines',
      birth_date: '1993-11-03',
      gender: 'M',
      sss_no: '34-4372051-7',
      ph_no: '010256342257',
      hdmf_no: '121120742901',
      tax_no: '444-802-339',
      taxcode: 'S',
      phone_no: '9770456816',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 252,
      fullName: 'Custodio, Vencio',
      employee_no: '15551',
      employee_name: 'Custodio, Vencio',
      country: 'Philippines',
      birth_date: '1973-08-23',
      gender: 'M',
      sss_no: '33-6605690-2',
      ph_no: '082014013233',
      hdmf_no: '121181942954',
      tax_no: '928-918-211',
      taxcode: 'S',
      phone_no: '9611942965',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 253,
      fullName: 'Vallente, John-An',
      employee_no: '77305',
      employee_name: 'Vallente, John-An',
      country: 'Philippines',
      birth_date: '1980-10-09',
      gender: 'F',
      sss_no: '33-5947541-9',
      ph_no: '190896053578',
      hdmf_no: '105001801718',
      tax_no: '210-800-367',
      taxcode: 'S',
      phone_no: '9325359324',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 254,
      fullName: 'Bacroya, Joeric',
      employee_no: '15566',
      employee_name: 'Bacroya, Joeric',
      country: 'Philippines',
      birth_date: '1972-01-10',
      gender: 'M',
      sss_no: '07-1254228-3',
      ph_no: '030502046701',
      hdmf_no: '107001304055',
      tax_no: '245-858-615',
      taxcode: 'ME2',
      phone_no: '9086054079',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 255,
      fullName: 'Galanta, Milo',
      employee_no: '15567',
      employee_name: 'Galanta, Milo',
      country: 'Philippines',
      birth_date: '1980-01-04',
      gender: 'M',
      sss_no: '33-6454429-6',
      ph_no: '030503009001',
      hdmf_no: '107001644127',
      tax_no: '240-927-501',
      taxcode: 'ME2',
      phone_no: '9058615389',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 256,
      fullName: 'Matin-Ao, Rolando',
      employee_no: '15569',
      employee_name: 'Matin-Ao, Rolando',
      country: 'Philippines',
      birth_date: '1976-08-05',
      gender: 'M',
      sss_no: '34-3094734-5',
      ph_no: '010518754233',
      hdmf_no: '121201035692',
      tax_no: '335-531-511',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 257,
      fullName: 'Fernandez, Gerry',
      employee_no: '15570',
      employee_name: 'Fernandez, Gerry',
      country: 'Philippines',
      birth_date: '1991-10-05',
      gender: 'M',
      sss_no: '04-2148562-8',
      ph_no: '080510038472',
      hdmf_no: '121115314088',
      tax_no: '443-203-058',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 258,
      fullName: 'Mata, Christian Carlo',
      employee_no: '15571',
      employee_name: 'Mata, Christian Carlo',
      country: 'Philippines',
      birth_date: '1996-12-19',
      gender: 'M',
      sss_no: '34-4718305-3',
      ph_no: '020268771876',
      hdmf_no: '121195264781',
      tax_no: '711-635-341',
      taxcode: 'HF',
      phone_no: '9195371312',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 259,
      fullName: 'Dominguez, Joshua',
      employee_no: '90920',
      employee_name: 'Dominguez, Joshua',
      country: 'Philippines',
      birth_date: '1997-03-04',
      gender: 'M',
      sss_no: '34-5022465-3',
      ph_no: '020266102276',
      hdmf_no: '121198460820',
      tax_no: '762-200-929',
      taxcode: 'S',
      phone_no: '9502064756',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 260,
      fullName: 'Vacaro Jr, Jaime',
      employee_no: '15572',
      employee_name: 'Vacaro Jr, Jaime',
      country: 'Philippines',
      birth_date: '1979-05-09',
      gender: 'M',
      sss_no: '33-6585155-1',
      ph_no: '190896277514',
      hdmf_no: '108000014809',
      tax_no: '244-090-719',
      taxcode: 'ME2',
      phone_no: '9984224218',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 261,
      fullName: 'Sabanal, Dexter',
      employee_no: '15573',
      employee_name: 'Sabanal, Dexter',
      country: 'Philippines',
      birth_date: '1994-06-11',
      gender: 'M',
      sss_no: '34-7530063-3',
      ph_no: '030263173437',
      hdmf_no: '121226329531',
      tax_no: '349-404-545',
      taxcode: 'S',
      phone_no: '9121677556',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 262,
      fullName: 'Razonable, Junel',
      employee_no: '03128',
      employee_name: 'Razonable, Junel',
      country: 'Philippines',
      birth_date: '1998-01-17',
      gender: 'M',
      sss_no: '34-6077064-7',
      ph_no: '010259787179',
      hdmf_no: '121175210375',
      tax_no: '337-000-058',
      taxcode: 'S',
      phone_no: '9099081983',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 263,
      fullName: 'Morilla, Cristian',
      employee_no: '15595',
      employee_name: 'Morilla, Cristian',
      country: 'Philippines',
      birth_date: '1997-08-25',
      gender: 'M',
      sss_no: '04-3794386-6',
      ph_no: '030260543381',
      hdmf_no: '121179168121',
      tax_no: '483-734-013',
      taxcode: 'S',
      phone_no: '9071041707',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 264,
      fullName: 'Samontan, Rodel',
      employee_no: '15606',
      employee_name: 'Samontan, Rodel',
      country: 'Philippines',
      birth_date: '1985-07-06',
      gender: 'M',
      sss_no: '33-9807651-0',
      ph_no: '020507536779',
      hdmf_no: '105001759644',
      tax_no: '302-826-487',
      taxcode: 'ME3',
      phone_no: '9771296749',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 265,
      fullName: 'Pascua, Avelino',
      employee_no: '15607',
      employee_name: 'Pascua, Avelino',
      country: 'Philippines',
      birth_date: '1972-03-17',
      gender: 'M',
      sss_no: '33-2397485-2',
      ph_no: '190904642731',
      hdmf_no: '121053332366',
      tax_no: '231-586-960',
      taxcode: 'ME1',
      phone_no: '9759120196',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 266,
      fullName: 'Aguinaldo, Jeffrey',
      employee_no: '15608',
      employee_name: 'Aguinaldo, Jeffrey',
      country: 'Philippines',
      birth_date: '1994-08-28',
      gender: 'M',
      sss_no: '34-6631611-5',
      ph_no: '042242854653',
      hdmf_no: '121195315112',
      tax_no: '363-583-125',
      taxcode: 'S',
      phone_no: '9162531715',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 267,
      fullName: 'Bandulin, Ian',
      employee_no: '90929',
      employee_name: 'Bandulin, Ian',
      country: 'Philippines',
      birth_date: '1998-07-07',
      gender: 'M',
      sss_no: '34-9214524-0',
      ph_no: '010265741555',
      hdmf_no: '121267602043',
      tax_no: '364-519-134',
      taxcode: 'S',
      phone_no: '9756546041',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 268,
      fullName: 'Placios, Mark Joseph',
      employee_no: '15614',
      employee_name: 'Placios, Mark Joseph',
      country: 'Philippines',
      birth_date: '1989-12-25',
      gender: 'M',
      sss_no: '34-3053007-9',
      ph_no: '102019907675',
      hdmf_no: '121038137648',
      tax_no: '450-817-475',
      taxcode: 'S',
      phone_no: '9487537129',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 269,
      fullName: 'Rosario, Airo',
      employee_no: '15615',
      employee_name: 'Rosario, Airo',
      country: 'Philippines',
      birth_date: '1989-11-11',
      gender: 'M',
      sss_no: '05-1154645-1',
      ph_no: '020510549938',
      hdmf_no: '121110511726',
      tax_no: '763-925-356',
      taxcode: 'S',
      phone_no: '9994713611',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 270,
      fullName: 'Lumaad, Giovanie',
      employee_no: '03147',
      employee_name: 'Lumaad, Giovanie',
      country: 'Philippines',
      birth_date: '1994-06-27',
      gender: 'M',
      sss_no: '34-4969391-0',
      ph_no: '030258027694',
      hdmf_no: '121147533203',
      tax_no: '470-097-409',
      taxcode: 'S',
      phone_no: '9270726361',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 271,
      fullName: 'Altura, Jene',
      employee_no: '55593',
      employee_name: 'Altura, Jene',
      country: 'Philippines',
      birth_date: '1986-06-18',
      gender: 'M',
      sss_no: '33-9961001-4',
      ph_no: '030258524884',
      hdmf_no: '121139505285',
      tax_no: '254-063-135',
      taxcode: 'S',
      phone_no: '9393812128',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 272,
      fullName: 'Del Mundo, Raymond',
      employee_no: '15622',
      employee_name: 'Del Mundo, Raymond',
      country: 'Philippines',
      birth_date: '1976-10-02',
      gender: 'M',
      sss_no: '04-0998471-4',
      ph_no: '080501958429',
      hdmf_no: '121048132731',
      tax_no: '190-906-996',
      taxcode: 'ME4',
      phone_no: '9463095928',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 273,
      fullName: 'Agoyaoy, Carlen',
      employee_no: '15626',
      employee_name: 'Agoyaoy, Carlen',
      country: 'Philippines',
      birth_date: '1987-12-07',
      gender: 'F',
      sss_no: '02-2686613-8',
      ph_no: '010254407491',
      hdmf_no: '121120648438',
      tax_no: '368-393-184',
      taxcode: 'S',
      phone_no: '9354248171',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 274,
      fullName: 'Helyang, Marlon',
      employee_no: '15651',
      employee_name: 'Helyang, Marlon',
      country: 'Philippines',
      birth_date: '1974-09-28',
      gender: 'M',
      sss_no: '33-5210432-1',
      ph_no: '110502102842',
      hdmf_no: '121186521990',
      tax_no: '200-571-216',
      taxcode: 'ME3',
      phone_no: '9380786617',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 275,
      fullName: 'Carubio, Erwin',
      employee_no: '15670',
      employee_name: 'Carubio, Erwin',
      country: 'Philippines',
      birth_date: '1990-07-27',
      gender: 'M',
      sss_no: '04-2616593-0',
      ph_no: '080514500022',
      hdmf_no: '121219484532',
      tax_no: '430-659-829',
      taxcode: 'S',
      phone_no: '9291205699',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 276,
      fullName: 'Tugadi, Marlon',
      employee_no: '15707',
      employee_name: 'Tugadi, Marlon',
      country: 'Philippines',
      birth_date: '1980-01-07',
      gender: 'M',
      sss_no: '33-5398764-4',
      ph_no: '090250490649',
      hdmf_no: '121130259978',
      tax_no: '457-016-372',
      taxcode: 'S1',
      phone_no: '9514354320',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 277,
      fullName: 'Camo, Allan',
      employee_no: '15708',
      employee_name: 'Camo, Allan',
      country: 'Philippines',
      birth_date: '1983-03-10',
      gender: 'M',
      sss_no: '04-1300976-6',
      ph_no: '030510626652',
      hdmf_no: '108002944832',
      tax_no: '429-750-875',
      taxcode: 'S1',
      phone_no: '9307790058',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 278,
      fullName: 'Quibel Jr., Gerardo',
      employee_no: '15730',
      employee_name: 'Quibel Jr., Gerardo',
      country: 'Philippines',
      birth_date: '1983-01-11',
      gender: 'M',
      sss_no: '06-3442978-5',
      ph_no: '122017268994',
      hdmf_no: '121178108449',
      tax_no: '746-165-531',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 279,
      fullName: 'Viana, Marnel Cedric',
      employee_no: '15732',
      employee_name: 'Viana, Marnel Cedric',
      country: 'Philippines',
      birth_date: '1995-09-09',
      gender: 'M',
      sss_no: '04-3755993-3',
      ph_no: '090505307533',
      hdmf_no: '121218386050',
      tax_no: '728-096-617',
      taxcode: 'HF',
      phone_no: '9060134500',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 280,
      fullName: 'Familaran, Bernardo',
      employee_no: '15733',
      employee_name: 'Familaran, Bernardo',
      country: 'Philippines',
      birth_date: '1973-07-06',
      gender: 'M',
      sss_no: '04-3167625-2',
      ph_no: '090502525335',
      hdmf_no: '121124157247',
      tax_no: '462-207-120',
      taxcode: 'ME',
      phone_no: '9126360578',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 281,
      fullName: 'Llames, Arjel',
      employee_no: '15734',
      employee_name: 'Llames, Arjel',
      country: 'Philippines',
      birth_date: '1994-03-19',
      gender: 'M',
      sss_no: '04-3599308-3',
      ph_no: '102027129746',
      hdmf_no: '121216371910',
      tax_no: '746-165-477',
      taxcode: 'S',
      phone_no: '9261816883',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 282,
      fullName: 'Nebres, Ryan',
      employee_no: '15741',
      employee_name: 'Nebres, Ryan',
      country: 'Philippines',
      birth_date: '1984-04-29',
      gender: 'M',
      sss_no: '05-0587469-2',
      ph_no: '090501593388',
      hdmf_no: '121219133249',
      tax_no: '703-402-816',
      taxcode: 'ME1',
      phone_no: '9156110788',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 283,
      fullName: 'Atienza, Ervinson',
      employee_no: '15742',
      employee_name: 'Atienza, Ervinson',
      country: 'Philippines',
      birth_date: '1990-10-19',
      gender: 'M',
      sss_no: '04-2902663-6',
      ph_no: '090255383808',
      hdmf_no: '121194238055',
      tax_no: '339-173-361',
      taxcode: 'HF',
      phone_no: '9264329638',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 284,
      fullName: 'Aguirre, Darwin',
      employee_no: '15743',
      employee_name: 'Aguirre, Darwin',
      country: 'Philippines',
      birth_date: '1998-06-26',
      gender: 'M',
      sss_no: '04-4156121-8',
      ph_no: '092538873510',
      hdmf_no: '121238279587',
      tax_no: '734-301-501',
      taxcode: 'S',
      phone_no: '9652811685',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 285,
      fullName: 'Soler, Jeppy',
      employee_no: '15744',
      employee_name: 'Soler, Jeppy',
      country: 'Philippines',
      birth_date: '1986-04-27',
      gender: 'M',
      sss_no: '34-1421858-2',
      ph_no: '020256381843',
      hdmf_no: '121096754282',
      tax_no: '338-503-693',
      taxcode: 'S',
      phone_no: '9384186559',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 286,
      fullName: 'Villanueva, Renante',
      employee_no: '15745',
      employee_name: 'Villanueva, Renante',
      country: 'Philippines',
      birth_date: '1980-01-15',
      gender: 'M',
      sss_no: '33-4671812-1',
      ph_no: '190895867881',
      hdmf_no: '121076210303',
      tax_no: '271-129-708',
      taxcode: 'ME3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 287,
      fullName: 'Mabubay, Riza',
      employee_no: '15747',
      employee_name: 'Mabubay, Riza',
      country: 'Philippines',
      birth_date: '1992-06-12',
      gender: 'M',
      sss_no: '34-3457317-1',
      ph_no: '130253757982',
      hdmf_no: '121205179333',
      tax_no: '339-532-567',
      taxcode: 'S',
      phone_no: '9951022443',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 288,
      fullName: 'Soliven, Lindo',
      employee_no: '15775',
      employee_name: 'Soliven, Lindo',
      country: 'Philippines',
      birth_date: '1983-08-18',
      gender: 'M',
      sss_no: '04-1108786-1',
      ph_no: '082010001230',
      hdmf_no: '121086521875',
      tax_no: '215-465-574',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 289,
      fullName: 'Beri¤a, Joey',
      employee_no: '15777',
      employee_name: 'Beri¤a, Joey',
      country: 'Philippines',
      birth_date: '1987-11-05',
      gender: 'M',
      sss_no: '35-0105103-4',
      ph_no: '062526072699',
      hdmf_no: '121277296845',
      tax_no: '774-325-995',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 290,
      fullName: 'Gaviola, Ian',
      employee_no: '15778',
      employee_name: 'Gaviola, Ian',
      country: 'Philippines',
      birth_date: '1996-03-06',
      gender: 'M',
      sss_no: '34-4681762-1',
      ph_no: '092020605237',
      hdmf_no: '121124918957',
      tax_no: '318-528-315',
      taxcode: 'S',
      phone_no: '9471005509',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 291,
      fullName: 'Morcilla, Christopher',
      employee_no: '15779',
      employee_name: 'Morcilla, Christopher',
      country: 'Philippines',
      birth_date: '1975-03-16',
      gender: 'M',
      sss_no: '04-0863148-2',
      ph_no: '090502245456',
      hdmf_no: '121229847712',
      tax_no: '221-915-583',
      taxcode: 'HF',
      phone_no: '9217544455',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 292,
      fullName: 'Rule, Ellen',
      employee_no: '15790',
      employee_name: 'Rule, Ellen',
      country: 'Philippines',
      birth_date: '1968-12-26',
      gender: 'F',
      sss_no: '33-2594288-2',
      ph_no: '082011061229',
      hdmf_no: '121258118457',
      tax_no: '           ',
      taxcode: 'S3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 293,
      fullName: 'Dumpit Jr, Carlos',
      employee_no: '15793',
      employee_name: 'Dumpit Jr, Carlos',
      country: 'Philippines',
      birth_date: '1980-11-26',
      gender: 'M',
      sss_no: '33-9130309-9',
      ph_no: '030507535921',
      hdmf_no: '107001170855',
      tax_no: '405-509-977',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 294,
      fullName: 'Camata, Bobby',
      employee_no: '15795',
      employee_name: 'Camata, Bobby',
      country: 'Philippines',
      birth_date: '1985-09-24',
      gender: 'M',
      sss_no: '33-8392290-9',
      ph_no: '100000987780',
      hdmf_no: '121141236719',
      tax_no: '208-394-043',
      taxcode: 'ME',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 295,
      fullName: 'Bana Jr, Rodolfo',
      employee_no: '15797',
      employee_name: 'Bana Jr, Rodolfo',
      country: 'Philippines',
      birth_date: '1981-07-27',
      gender: 'M',
      sss_no: '04-2053060-0',
      ph_no: '080509665188',
      hdmf_no: '121036539305',
      tax_no: '346-982-228',
      taxcode: 'ME3',
      phone_no: '9383579399',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 296,
      fullName: 'Dunyard, Davezon',
      employee_no: '15798',
      employee_name: 'Dunyard, Davezon',
      country: 'Philippines',
      birth_date: '1986-02-15',
      gender: 'M',
      sss_no: '34-4004950-5',
      ph_no: '020260058267',
      hdmf_no: '121116781761',
      tax_no: '449-972-002',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 297,
      fullName: 'Lazarte, Reynante',
      employee_no: '15807',
      employee_name: 'Lazarte, Reynante',
      country: 'Philippines',
      birth_date: '1983-05-14',
      gender: 'M',
      sss_no: '33-8267324-6',
      ph_no: '090250550587',
      hdmf_no: '109002058330',
      tax_no: '275-396-423',
      taxcode: 'S3',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 298,
      fullName: 'Babanto Jr, Diomedes',
      employee_no: '15809',
      employee_name: 'Babanto Jr, Diomedes',
      country: 'Philippines',
      birth_date: '1985-04-10',
      gender: 'M',
      sss_no: '06-2341614-9',
      ph_no: '120504348623',
      hdmf_no: '167001142000',
      tax_no: '269-126-171',
      taxcode: 'ME1',
      phone_no: '9384541551',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 299,
      fullName: 'Lozada, Jessa',
      employee_no: '15816',
      employee_name: 'Lozada, Jessa',
      country: 'Philippines',
      birth_date: '1997-03-29',
      gender: 'F',
      sss_no: '07-3717670-2',
      ph_no: '112511715116',
      hdmf_no: '121214184896',
      tax_no: '712-446-149',
      taxcode: 'S',
      phone_no: '9260022167',
      email: '',
      avatar: '',
      status: 'R',
    },
    {
      id: 300,
      fullName: 'Madia, Jerryson',
      employee_no: '15867',
      employee_name: 'Madia, Jerryson',
      country: 'Philippines',
      birth_date: '1977-11-18',
      gender: 'M',
      sss_no: '04-1274561-0',
      ph_no: '090501825270',
      hdmf_no: '121174993933',
      tax_no: '939-498-973',
      taxcode: 'ME3',
      phone_no: '9308710735',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 301,
      fullName: 'Ani, Epefania',
      employee_no: '15880',
      employee_name: 'Ani, Epefania',
      country: 'Philippines',
      birth_date: '1988-04-13',
      gender: 'F',
      sss_no: '05-1149064-6',
      ph_no: '100251516906',
      hdmf_no: '121134902661',
      tax_no: '711-902-300',
      taxcode: 'S',
      phone_no: '9462733914',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 302,
      fullName: 'Perino, Joy Abigail',
      employee_no: '15881',
      employee_name: 'Perino, Joy Abigail',
      country: 'Philippines',
      birth_date: '1995-06-05',
      gender: 'F',
      sss_no: '06-3647658-7',
      ph_no: '010257649143',
      hdmf_no: '121141814202',
      tax_no: '320-852-453',
      taxcode: 'ME2',
      phone_no: '9121145525',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 303,
      fullName: 'Cardama, Johnry',
      employee_no: '15887',
      employee_name: 'Cardama, Johnry',
      country: 'Philippines',
      birth_date: '1998-01-19',
      gender: 'M',
      sss_no: '04-3815184-8',
      ph_no: '100252822458',
      hdmf_no: '121194482945',
      tax_no: '375-281-643',
      taxcode: 'S',
      phone_no: '9127806176',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 304,
      fullName: 'Borja, Ruben',
      employee_no: '15889',
      employee_name: 'Borja, Ruben',
      country: 'Philippines',
      birth_date: '1988-05-07',
      gender: 'M',
      sss_no: '34-5737487-8',
      ph_no: '030259190645',
      hdmf_no: '121164855019',
      tax_no: '600-839-536',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 305,
      fullName: 'Borromeo, Jay-R',
      employee_no: '15890',
      employee_name: 'Borromeo, Jay-R',
      country: 'Philippines',
      birth_date: '1988-11-01',
      gender: 'M',
      sss_no: '34-2697510-1',
      ph_no: '080511133495',
      hdmf_no: '121011327828',
      tax_no: '309-663-748',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 306,
      fullName: 'Mercano, Sara Jane',
      employee_no: '15891',
      employee_name: 'Mercano, Sara Jane',
      country: 'Philippines',
      birth_date: '1998-06-10',
      gender: 'F',
      sss_no: '34-8248856-4',
      ph_no: '080265940785',
      hdmf_no: '121250579719',
      tax_no: '745-524-080',
      taxcode: 'S',
      phone_no: '9123674357',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 307,
      fullName: 'Atie, Mark Kevin',
      employee_no: '15892',
      employee_name: 'Atie, Mark Kevin',
      country: 'Philippines',
      birth_date: '1991-07-25',
      gender: ' ',
      sss_no: '34-1750367-6',
      ph_no: '080512704771',
      hdmf_no: '121030665887',
      tax_no: '505-187-702',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 308,
      fullName: 'Ibes, Erwin',
      employee_no: '15894',
      employee_name: 'Ibes, Erwin',
      country: 'Philippines',
      birth_date: '1984-06-28',
      gender: 'M',
      sss_no: '33-9721993-8',
      ph_no: '010505292028',
      hdmf_no: '121198542636',
      tax_no: '296-738-800',
      taxcode: 'S',
      phone_no: '9121528297',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 309,
      fullName: 'Mendoza Jr, Arnel',
      employee_no: '15895',
      employee_name: 'Mendoza Jr, Arnel',
      country: 'Philippines',
      birth_date: '1987-12-09',
      gender: 'M',
      sss_no: '34-0199038-9',
      ph_no: '080507235127',
      hdmf_no: '103001815115',
      tax_no: '293-980-247',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 310,
      fullName: 'Gonzales, Roland',
      employee_no: '15896',
      employee_name: 'Gonzales, Roland',
      country: 'Philippines',
      birth_date: '1992-07-20',
      gender: 'M',
      sss_no: '05-1484248-2',
      ph_no: '100502670985',
      hdmf_no: '121239247658',
      tax_no: '503-963-764',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 311,
      fullName: 'Pacle, Jimboy',
      employee_no: '15897',
      employee_name: 'Pacle, Jimboy',
      country: 'Philippines',
      birth_date: '1995-04-20',
      gender: 'M',
      sss_no: '34-4430459-2',
      ph_no: '030255754994',
      hdmf_no: '121114294764',
      tax_no: '482-154-685',
      taxcode: 'S',
      phone_no: '9563350605',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 312,
      fullName: 'Tesorero, Carlo',
      employee_no: '15944',
      employee_name: 'Tesorero, Carlo',
      country: 'Philippines',
      birth_date: '1989-12-08',
      gender: 'M',
      sss_no: '05-0985881-8',
      ph_no: '102021808900',
      hdmf_no: '121269946020',
      tax_no: '511-884-904',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 313,
      fullName: 'Ruzol, Erickson',
      employee_no: '15945',
      employee_name: 'Ruzol, Erickson',
      country: 'Philippines',
      birth_date: '1987-02-27',
      gender: 'M',
      sss_no: '04-1856079-2',
      ph_no: '010509579550',
      hdmf_no: '121007205046',
      tax_no: '           ',
      taxcode: 'ME',
      phone_no: '9090162262',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 314,
      fullName: 'Ernesto, Jester',
      employee_no: '15946',
      employee_name: 'Ernesto, Jester',
      country: 'Philippines',
      birth_date: '1994-11-26',
      gender: 'M',
      sss_no: '05-1459509-8',
      ph_no: '100253597431',
      hdmf_no: '121250150887',
      tax_no: '           ',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 315,
      fullName: 'Corocoto, Erica',
      employee_no: '15947',
      employee_name: 'Corocoto, Erica',
      country: 'Philippines',
      birth_date: '1993-12-30',
      gender: 'F',
      sss_no: '34-3942346-2',
      ph_no: '020510045867',
      hdmf_no: '121112180287',
      tax_no: '438-241-964',
      taxcode: 'ME2',
      phone_no: '96785237592',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 316,
      fullName: 'Tiuseco, Ryan',
      employee_no: '15948',
      employee_name: 'Tiuseco, Ryan',
      country: 'Philippines',
      birth_date: '1991-02-23',
      gender: 'M',
      sss_no: '04-2091271-0',
      ph_no: '070506345500',
      hdmf_no: '121081853440',
      tax_no: '283-318-092',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 317,
      fullName: 'Mutia, Salman',
      employee_no: '15949',
      employee_name: 'Mutia, Salman',
      country: 'Philippines',
      birth_date: '1983-10-13',
      gender: 'M',
      sss_no: '06-3463000-8',
      ph_no: '120513120287',
      hdmf_no: '121142147377',
      tax_no: '           ',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'R',
    },
    {
      id: 318,
      fullName: 'Ocmer, Ramnani',
      employee_no: '15951',
      employee_name: 'Ocmer, Ramnani',
      country: 'Philippines',
      birth_date: '1983-03-01',
      gender: 'M',
      sss_no: '33-9165277-9',
      ph_no: '102020958941',
      hdmf_no: '121154409754',
      tax_no: '475-306-856',
      taxcode: 'S',
      phone_no: '9216469004',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 319,
      fullName: 'Macayan, Jason',
      employee_no: '15952',
      employee_name: 'Macayan, Jason',
      country: 'Philippines',
      birth_date: '1988-01-30',
      gender: 'M',
      sss_no: '05-0949517-4',
      ph_no: '100502210666',
      hdmf_no: '121243229150',
      tax_no: '441-882-359',
      taxcode: 'ME',
      phone_no: '9515285317',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 320,
      fullName: 'Hepolito, Ryan',
      employee_no: '15953',
      employee_name: 'Hepolito, Ryan',
      country: 'Philippines',
      birth_date: '1990-06-03',
      gender: 'M',
      sss_no: '06-2723187-0',
      ph_no: '130500796058',
      hdmf_no: '121179384255',
      tax_no: '459-972-599',
      taxcode: 'S',
      phone_no: '9457203590',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 321,
      fullName: 'Nantes, John',
      employee_no: '15968',
      employee_name: 'Nantes, John',
      country: 'Philippines',
      birth_date: '1985-12-23',
      gender: 'M',
      sss_no: '04-1607199-5',
      ph_no: '102022041336',
      hdmf_no: '121081422072',
      tax_no: '761-073-379',
      taxcode: 'S3',
      phone_no: '9229821030',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 322,
      fullName: 'Raborar, Dielmar',
      employee_no: '15969',
      employee_name: 'Raborar, Dielmar',
      country: 'Philippines',
      birth_date: '1985-11-14',
      gender: 'M',
      sss_no: '34-0683694-3',
      ph_no: '030261307010',
      hdmf_no: '121192056973',
      tax_no: '513-942-279',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 323,
      fullName: 'Perpetua, John Carlo',
      employee_no: '15970',
      employee_name: 'Perpetua, John Carlo',
      country: 'Philippines',
      birth_date: '1990-07-09',
      gender: 'M',
      sss_no: '05-1122160-8',
      ph_no: '100502297877',
      hdmf_no: '121148238517',
      tax_no: '471-949-926',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 324,
      fullName: 'Moral, Joseph',
      employee_no: '16010',
      employee_name: 'Moral, Joseph',
      country: 'Philippines',
      birth_date: '1990-12-28',
      gender: 'M',
      sss_no: '05-0964614-3',
      ph_no: '100501799526',
      hdmf_no: '121124601636',
      tax_no: '444-851-428',
      taxcode: 'S',
      phone_no: '9306597163',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 325,
      fullName: 'Omayan, Joshua',
      employee_no: '16011',
      employee_name: 'Omayan, Joshua',
      country: 'Philippines',
      birth_date: '1999-01-19',
      gender: 'M',
      sss_no: '35-1465567-6',
      ph_no: '100254724623',
      hdmf_no: '121292361287',
      tax_no: '           ',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 326,
      fullName: 'Flores, Joefel',
      employee_no: '16012',
      employee_name: 'Flores, Joefel',
      country: 'Philippines',
      birth_date: '1990-12-06',
      gender: 'M',
      sss_no: '04-2603517-0',
      ph_no: '112017330600',
      hdmf_no: '121233838240',
      tax_no: '755-133-167',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 327,
      fullName: 'Cortez, Jessie May',
      employee_no: '16014',
      employee_name: 'Cortez, Jessie May',
      country: 'Philippines',
      birth_date: '1990-05-18',
      gender: 'F',
      sss_no: '07-3846014-5',
      ph_no: '112035727216',
      hdmf_no: '121292674424',
      tax_no: '           ',
      taxcode: 'ME2',
      phone_no: '9317807023',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 328,
      fullName: 'Ticay, Dalia',
      employee_no: '16015',
      employee_name: 'Ticay, Dalia',
      country: 'Philippines',
      birth_date: '1989-10-22',
      gender: 'F',
      sss_no: '34-2604958-7',
      ph_no: '130251481637',
      hdmf_no: '121143043268',
      tax_no: '325-026-125',
      taxcode: 'S2',
      phone_no: '9466777529',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 329,
      fullName: 'Fabio, Lara Jane',
      employee_no: '16016',
      employee_name: 'Fabio, Lara Jane',
      country: 'Philippines',
      birth_date: '1996-01-19',
      gender: 'F',
      sss_no: '34-5077472-1',
      ph_no: '010521360041',
      hdmf_no: '121150003799',
      tax_no: '330-317-764',
      taxcode: 'S',
      phone_no: '9751165215',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 330,
      fullName: 'Labastida, Liezel',
      employee_no: '16017',
      employee_name: 'Labastida, Liezel',
      country: 'Philippines',
      birth_date: '1984-08-28',
      gender: 'F',
      sss_no: '34-7158372-6',
      ph_no: '020266091355',
      hdmf_no: '915302395920',
      tax_no: '489-502-653',
      taxcode: 'S',
      phone_no: '9102463726',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 331,
      fullName: 'Sibulo, Erwin',
      employee_no: '16018',
      employee_name: 'Sibulo, Erwin',
      country: 'Philippines',
      birth_date: '1996-06-01',
      gender: 'M',
      sss_no: '05-1253520-9',
      ph_no: '100252326973',
      hdmf_no: '121139663703',
      tax_no: '324-238-143',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 332,
      fullName: 'De Mesa, Josie',
      employee_no: '16019',
      employee_name: 'De Mesa, Josie',
      country: 'Philippines',
      birth_date: '1981-12-30',
      gender: 'F',
      sss_no: '33-6504863-6',
      ph_no: '080251416536',
      hdmf_no: '121175785794',
      tax_no: '330-839-785',
      taxcode: 'ME4',
      phone_no: '9159453080',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 333,
      fullName: 'Palin, Ronel',
      employee_no: '16020',
      employee_name: 'Palin, Ronel',
      country: 'Philippines',
      birth_date: '1992-05-20',
      gender: 'M',
      sss_no: '34-2099600-5',
      ph_no: '152019718373',
      hdmf_no: '121108434996',
      tax_no: '294-772-616',
      taxcode: 'S1',
      phone_no: '9704011103',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 334,
      fullName: 'Rodriguez, Elma',
      employee_no: '16021',
      employee_name: 'Rodriguez, Elma',
      country: 'Philippines',
      birth_date: '1992-06-04',
      gender: 'F',
      sss_no: '04-3869649-7',
      ph_no: '122022954871',
      hdmf_no: '121223112009',
      tax_no: '505-346-879',
      taxcode: 'S3',
      phone_no: '9513967328',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 335,
      fullName: 'Pardines, Joie',
      employee_no: '16022',
      employee_name: 'Pardines, Joie',
      country: 'Philippines',
      birth_date: '1989-01-26',
      gender: 'M',
      sss_no: '04-2214907-3',
      ph_no: '010252446112',
      hdmf_no: '121029308210',
      tax_no: '429-254-450',
      taxcode: 'S',
      phone_no: '9692789498',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 336,
      fullName: 'Bulauan, Jaypee',
      employee_no: '16024',
      employee_name: 'Bulauan, Jaypee',
      country: 'Philippines',
      birth_date: '1994-10-21',
      gender: 'M',
      sss_no: '34-4000819-3',
      ph_no: '030254456949',
      hdmf_no: '121096008242',
      tax_no: '853-895-827',
      taxcode: 'S',
      phone_no: '9272602078',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 337,
      fullName: 'Arellano, Julie',
      employee_no: '16032',
      employee_name: 'Arellano, Julie',
      country: 'Philippines',
      birth_date: '1987-03-04',
      gender: 'F',
      sss_no: '04-2699358-4',
      ph_no: '220001380702',
      hdmf_no: '121086637127',
      tax_no: '429-371-971',
      taxcode: 'ME2',
      phone_no: '9514343059',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 338,
      fullName: 'Mijares, Ruel',
      employee_no: '16033',
      employee_name: 'Mijares, Ruel',
      country: 'Philippines',
      birth_date: '1979-09-08',
      gender: 'M',
      sss_no: '07-1686073-8',
      ph_no: '030500396780',
      hdmf_no: '109002790802',
      tax_no: '265-618-164',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 339,
      fullName: 'Lagumbay, Melva',
      employee_no: '16037',
      employee_name: 'Lagumbay, Melva',
      country: 'Philippines',
      birth_date: '1977-03-02',
      gender: 'F',
      sss_no: '33-4000139-7',
      ph_no: '020500782677',
      hdmf_no: '121121234469',
      tax_no: '200-240-915',
      taxcode: 'ME4',
      phone_no: '9532152331',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 340,
      fullName: 'Betita, Graciela',
      employee_no: '16039',
      employee_name: 'Betita, Graciela',
      country: 'Philippines',
      birth_date: '1999-01-12',
      gender: 'F',
      sss_no: '34-9174237-2',
      ph_no: '082511937708',
      hdmf_no: '121266823517',
      tax_no: '771-127-869',
      taxcode: 'HF',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 341,
      fullName: 'Revidiso, Jerome',
      employee_no: '16043',
      employee_name: 'Revidiso, Jerome',
      country: 'Philippines',
      birth_date: '1989-10-18',
      gender: 'M',
      sss_no: '35-1438410-9',
      ph_no: '082011137543',
      hdmf_no: '121292151815',
      tax_no: '           ',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 342,
      fullName: 'Tenajeros, Dither Jay',
      employee_no: '16044',
      employee_name: 'Tenajeros, Dither Jay',
      country: 'Philippines',
      birth_date: '1996-10-14',
      gender: 'M',
      sss_no: '34-1089137-9',
      ph_no: '010266961613',
      hdmf_no: '121288370571',
      tax_no: '           ',
      taxcode: 'S',
      phone_no: '9050797538',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 343,
      fullName: 'Dida-Agun, Shainab',
      employee_no: '16045',
      employee_name: 'Dida-Agun, Shainab',
      country: 'Philippines',
      birth_date: '1994-08-01',
      gender: 'F',
      sss_no: '34-5071305-4',
      ph_no: '080259348939',
      hdmf_no: '121168088482',
      tax_no: '484-365-047',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
    {
      id: 344,
      fullName: 'Bareja, Oliver',
      employee_no: '16262',
      employee_name: 'Bareja, Oliver',
      country: 'Philippines',
      birth_date: '1999-10-12',
      gender: 'M',
      sss_no: '34-9835697-8',
      ph_no: '010262609117',
      hdmf_no: '121276049861',
      tax_no: '753-062-957',
      taxcode: 'S',
      phone_no: '',
      email: '',
      avatar: '',
      status: 'active',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Employees
// ------------------------------------------------
mock.onGet('/apps/employee/employees').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.employees.filter(
    employee =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (employee.employee_no.toLowerCase().includes(queryLowered) || employee.employee_name.toLowerCase().includes(queryLowered)) &&
      employee.role === (role || employee.role) &&
      employee.currentPlan === (plan || employee.currentPlan) &&
      employee.status === (status || employee.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      employees: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new employee
// ------------------------------------------------
mock.onPost('/apps/employee/employees').reply(config => {
  // Get event from post data
  const { employee } = JSON.parse(config.data)

  // Assign Status
  employee.status = 'active'

  const { length } = data.employees
  let lastIndex = 0
  if (length) {
    lastIndex = data.employees[length - 1].id
  }
  employee.id = lastIndex + 1

  data.employees.push(employee)

  return [201, { employee }]
})

// ------------------------------------------------
// GET: Return Single Employee
// ------------------------------------------------
mock.onGet(/\/apps\/employee\/employees\/\d+/).reply(config => {
  // Get event id from URL
  let employeeId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  employeeId = Number(employeeId)

  const employeeIndex = data.employees.findIndex(e => e.id === employeeId)
  const employee = data.employees[employeeIndex]

  if (employee) return [200, employee]
  return [404]
})
