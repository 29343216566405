import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  clients: [
    {
      id:       1,
      fullName: "Alabang Commercial Corp. - PARKING AREAS",
      client_no: '10001',
      client_name: "Alabang Commercial Corp. - PARKING AREAS",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       2,
      fullName: "Alabang Commercial Corp.  - EXPANSION MALL",
      client_no: '10006',
      client_name: "Alabang Commercial Corp.  - EXPANSION MALL",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       3,
      fullName: "Alabang Commercial Center - EXISTING MALL",
      client_no: '10007',
      client_name: "Alabang Commercial Center - EXISTING MALL",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       4,
      fullName: "ALABANG COMMERCIAL CENTER - CINEMAS",
      client_no: '10011',
      client_name: "ALABANG COMMERCIAL CENTER - CINEMAS",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       5,
      fullName: "ALABANG COMMERCIAL CORP. - PARKING AREAS (EXTRA DUTY)",
      client_no: '10013',
      client_name: "ALABANG COMMERCIAL CORP. - PARKING AREAS (EXTRA DUTY)",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       6,
      fullName: "Bank of the Phil. Islands - ARRANQUE BRANCH",
      client_no: '10022',
      client_name: "Bank of the Phil. Islands - ARRANQUE BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       7,
      fullName: "Bank of the Phil. Islands - PLAZA LORENZO RUIZ BRANCH",
      client_no: '10024',
      client_name: "Bank of the Phil. Islands - PLAZA LORENZO RUIZ BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       8,
      fullName: "Bank of the Phil. Islands - BINONDO ROSARIO BRANCH",
      client_no: '10025',
      client_name: "Bank of the Phil. Islands - BINONDO ROSARIO BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:       9,
      fullName: "Bank of the Phil. Islands - CARMEN PLANAS BRANCH",
      client_no: '10029',
      client_name: "Bank of the Phil. Islands - CARMEN PLANAS BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      10,
      fullName: "Bank of the Phil. Islands - ESCOLTA BRANCH",
      client_no: '10030',
      client_name: "Bank of the Phil. Islands - ESCOLTA BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      11,
      fullName: "Bank of the Phil. Islands - MASANGKAY-MAYHALIGUE BRANCH",
      client_no: '10032',
      client_name: "Bank of the Phil. Islands - MASANGKAY-MAYHALIGUE BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      12,
      fullName: "Bank of the Phil. Islands - NUEVA BRANCH",
      client_no: '10033',
      client_name: "Bank of the Phil. Islands - NUEVA BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      13,
      fullName: "Bank of the Phil. Islands - PRITIL BRANCH",
      client_no: '10036',
      client_name: "Bank of the Phil. Islands - PRITIL BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      14,
      fullName: "Bank of the Phil. Islands - TEODORA ALONZO BRANCH",
      client_no: '10039',
      client_name: "Bank of the Phil. Islands - TEODORA ALONZO BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      15,
      fullName: "GREENHILLS CHRISTIAN FELLOWSHIP-SOUTH METRO",
      client_no: '10046',
      client_name: "GREENHILLS CHRISTIAN FELLOWSHIP-SOUTH METRO",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      16,
      fullName: "Gift Gate Inc. - HEAD OFFICE CUBAO",
      client_no: '10048',
      client_name: "Gift Gate Inc. - HEAD OFFICE CUBAO",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      17,
      fullName: "ISUZU AUTOMOTIVE DEALERSHIP, INC. - Imus Cavite",
      client_no: '10058',
      client_name: "ISUZU AUTOMOTIVE DEALERSHIP, INC. - Imus Cavite",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      18,
      fullName: "La Elegancia - MAKATI BRANCH",
      client_no: '10062',
      client_name: "La Elegancia - MAKATI BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      19,
      fullName: "La Elegancia - SM MEGA MALL BRANCH",
      client_no: '10063',
      client_name: "La Elegancia - SM MEGA MALL BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      20,
      fullName: "MAFRE ASIAN CORPORATE CENTER CONDO. CORP.",
      client_no: '10114',
      client_name: "MAFRE ASIAN CORPORATE CENTER CONDO. CORP.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      21,
      fullName: "MIZUHO CORPORATE BANK LTD.",
      client_no: '10116',
      client_name: "MIZUHO CORPORATE BANK LTD.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      22,
      fullName: "MONTIVAR, INC.",
      client_no: '10117',
      client_name: "MONTIVAR, INC.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      23,
      fullName: "PARISH OF ST. JAMES THE GREAT",
      client_no: '10120',
      client_name: "PARISH OF ST. JAMES THE GREAT",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      24,
      fullName: "PHIL. TRANSMARINE CARRIER",
      client_no: '10121',
      client_name: "PHIL. TRANSMARINE CARRIER",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      25,
      fullName: "PILGRIM DEVELOPMENT CORP. (CSJ)",
      client_no: '10122',
      client_name: "PILGRIM DEVELOPMENT CORP. (CSJ)",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      26,
      fullName: "PIONEER INSURANCE & SURETY CORP.",
      client_no: '10123',
      client_name: "PIONEER INSURANCE & SURETY CORP.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      27,
      fullName: "PIONEER LIFE INC.",
      client_no: '10124',
      client_name: "PIONEER LIFE INC.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      28,
      fullName: "VILLAGE GOURMET",
      client_no: '10131',
      client_name: "VILLAGE GOURMET",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      29,
      fullName: "Bank of the Phil. Islands - JUAN LUNA, RECTO BRANCH",
      client_no: '10143',
      client_name: "Bank of the Phil. Islands - JUAN LUNA, RECTO BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      30,
      fullName: "Alabang Town Center - EXISITNG MALL (EXTRA DUTY)",
      client_no: '10148',
      client_name: "Alabang Town Center - EXISITNG MALL (EXTRA DUTY)",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      31,
      fullName: "Alabang Town Center - EXPANSION MALL (EXTRA DUTY)",
      client_no: '10149',
      client_name: "Alabang Town Center - EXPANSION MALL (EXTRA DUTY)",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      32,
      fullName: "Bank of the Phil. Islands - TUTUBAN Branch",
      client_no: '10150',
      client_name: "Bank of the Phil. Islands - TUTUBAN Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      33,
      fullName: "Bank of the Phil. Islands - EL CANO TOWER BRANCH",
      client_no: '10152',
      client_name: "Bank of the Phil. Islands - EL CANO TOWER BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      34,
      fullName: "SAFEGUARD OVERHEAD (ATC/MAP)",
      client_no: '10153',
      client_name: "SAFEGUARD OVERHEAD (ATC/MAP)",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      35,
      fullName: "SAFEGUARD PAYROLL STAFF",
      client_no: '10155',
      client_name: "SAFEGUARD PAYROLL STAFF",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      36,
      fullName: "CHRIST's COMMISION FELLOWSHIP",
      client_no: '10156',
      client_name: "CHRIST's COMMISION FELLOWSHIP",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      37,
      fullName: "MCS TOWER CONDOMINIUM UNIT OWNERS ASSOCIATION",
      client_no: '10229',
      client_name: "MCS TOWER CONDOMINIUM UNIT OWNERS ASSOCIATION",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      38,
      fullName: "HONDA CARS ALABANG, INC.",
      client_no: '10230',
      client_name: "HONDA CARS ALABANG, INC.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      39,
      fullName: "ISUZU ALABANG",
      client_no: '10231',
      client_name: "ISUZU ALABANG",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      40,
      fullName: "ROHM ELECTRONICS PHILIPPINES, INC.-REPI",
      client_no: '10292',
      client_name: "ROHM ELECTRONICS PHILIPPINES, INC.-REPI",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      41,
      fullName: "ROHM MECHATECH PHILIPPINES, INC.-RMPI",
      client_no: '10293',
      client_name: "ROHM MECHATECH PHILIPPINES, INC.-RMPI",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      42,
      fullName: "BANK OF THE PHIL. ISLANDS - PORT AREA BRANCH",
      client_no: '10300',
      client_name: "BANK OF THE PHIL. ISLANDS - PORT AREA BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      43,
      fullName: "Bank of the Philippine Island - San Lazaro Vertex",
      client_no: '10326',
      client_name: "Bank of the Philippine Island - San Lazaro Vertex",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      44,
      fullName: "Honda Cars Inc., - Shaw Boulevard",
      client_no: '10331',
      client_name: "Honda Cars Inc., - Shaw Boulevard",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      45,
      fullName: "Toshiba Information Equipment (Phils) Inc - Canlubang Site",
      client_no: '10333',
      client_name: "Toshiba Information Equipment (Phils) Inc - Canlubang Site",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      46,
      fullName: "Isuzu Pasig",
      client_no: '10336',
      client_name: "Isuzu Pasig",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      47,
      fullName: "Isuzu Antipolo",
      client_no: '10337',
      client_name: "Isuzu Antipolo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      48,
      fullName: "Petrolift Inc., - Bauan, Batangas",
      client_no: '10348',
      client_name: "Petrolift Inc., - Bauan, Batangas",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      49,
      fullName: "Kingston Tower Development & Management Corp.",
      client_no: '10352',
      client_name: "Kingston Tower Development & Management Corp.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      50,
      fullName: "Bank of the Phils. Island - ATM Roving Guard",
      client_no: '10359',
      client_name: "Bank of the Phils. Island - ATM Roving Guard",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      51,
      fullName: "Czarina Foriegn Exchange - Forbes Park, Makati City",
      client_no: '10381',
      client_name: "Czarina Foriegn Exchange - Forbes Park, Makati City",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      52,
      fullName: "PTC - Philcamsat Training Facilities",
      client_no: '10385',
      client_name: "PTC - Philcamsat Training Facilities",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      53,
      fullName: "Alabang Town Center - CCTV Operator",
      client_no: '10386',
      client_name: "Alabang Town Center - CCTV Operator",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      54,
      fullName: "Iconic Dealership, Inc",
      client_no: '10392',
      client_name: "Iconic Dealership, Inc",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      55,
      fullName: "Bank of the Phil. Islands - Intramuros Building",
      client_no: '10394',
      client_name: "Bank of the Phil. Islands - Intramuros Building",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      56,
      fullName: "Bank of the Phil. Islands - Intramuros Branch",
      client_no: '10395',
      client_name: "Bank of the Phil. Islands - Intramuros Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      57,
      fullName: "Bank of the Phil. Islands - Gandara Branch",
      client_no: '10397',
      client_name: "Bank of the Phil. Islands - Gandara Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      58,
      fullName: "Bank of the Phil. Islands - Binondo, Ylaya Branch",
      client_no: '10398',
      client_name: "Bank of the Phil. Islands - Binondo, Ylaya Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      59,
      fullName: "Bank of the Phil. Islands - Blumentritt, Guevarra",
      client_no: '10399',
      client_name: "Bank of the Phil. Islands - Blumentritt, Guevarra",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      60,
      fullName: "Bank of the Phil. Islands - Blumentritt, Rizal Ave.",
      client_no: '10400',
      client_name: "Bank of the Phil. Islands - Blumentritt, Rizal Ave.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      61,
      fullName: "Bank of the Phil. Islands - Tayuman Branch",
      client_no: '10401',
      client_name: "Bank of the Phil. Islands - Tayuman Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      62,
      fullName: "Bank of the Phil. Islands - Balut, Tondo",
      client_no: '10402',
      client_name: "Bank of the Phil. Islands - Balut, Tondo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      63,
      fullName: "PTC - Health Metrics Inc",
      client_no: '10404',
      client_name: "PTC - Health Metrics Inc",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      64,
      fullName: "PTC QUANTUM FERON BLDG",
      client_no: '10431',
      client_name: "PTC QUANTUM FERON BLDG",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      65,
      fullName: "Iconic Quezon Ave. Branch",
      client_no: '10434',
      client_name: "Iconic Quezon Ave. Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      66,
      fullName: "Richville Hotel Mgt. Inc",
      client_no: '10438',
      client_name: "Richville Hotel Mgt. Inc",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      67,
      fullName: "Richville Regency Suites Condo",
      client_no: '10439',
      client_name: "Richville Regency Suites Condo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      68,
      fullName: "Alabang Town Center - Corporate Center",
      client_no: '10446',
      client_name: "Alabang Town Center - Corporate Center",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      69,
      fullName: "Gift Gate Benjamin Ramos Res",
      client_no: '10453',
      client_name: "Gift Gate Benjamin Ramos Res",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      70,
      fullName: "BANK OF THE PHIL. ISLANDS - Doroteo Jose",
      client_no: '10463',
      client_name: "BANK OF THE PHIL. ISLANDS - Doroteo Jose",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      71,
      fullName: "BANK OF THE Phil. Islands - Blumentrit-Chinese Gen Branch",
      client_no: '10464',
      client_name: "BANK OF THE Phil. Islands - Blumentrit-Chinese Gen Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      72,
      fullName: "Taisei Philippines Construction, Inc.",
      client_no: '10467',
      client_name: "Taisei Philippines Construction, Inc.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      73,
      fullName: "BANK OF THE PHIL. ISLANDS - Binondo Rosario Bldg.",
      client_no: '10470',
      client_name: "BANK OF THE PHIL. ISLANDS - Binondo Rosario Bldg.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      74,
      fullName: "LBP - Labo",
      client_no: '10472',
      client_name: "LBP - Labo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      75,
      fullName: "LBP - Daet",
      client_no: '10473',
      client_name: "LBP - Daet",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      76,
      fullName: "LBP - Sipicot",
      client_no: '10474',
      client_name: "LBP - Sipicot",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      77,
      fullName: "LBP - Naga",
      client_no: '10475',
      client_name: "LBP - Naga",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      78,
      fullName: "LBP - Pili",
      client_no: '10477',
      client_name: "LBP - Pili",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      79,
      fullName: "LBP - Tigaon",
      client_no: '10478',
      client_name: "LBP - Tigaon",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      80,
      fullName: "LBP - Goa",
      client_no: '10479',
      client_name: "LBP - Goa",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      81,
      fullName: "LBP - Polangui",
      client_no: '10481',
      client_name: "LBP - Polangui",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      82,
      fullName: "LBP - Ligao",
      client_no: '10482',
      client_name: "LBP - Ligao",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      83,
      fullName: "LBP - Daraga",
      client_no: '10483',
      client_name: "LBP - Daraga",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      84,
      fullName: "LBP - Legaspi",
      client_no: '10484',
      client_name: "LBP - Legaspi",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      85,
      fullName: "LBP - Sorsogon",
      client_no: '10485',
      client_name: "LBP - Sorsogon",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      86,
      fullName: "LBP - Irosin",
      client_no: '10486',
      client_name: "LBP - Irosin",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      87,
      fullName: "LBP - Tabaco",
      client_no: '10487',
      client_name: "LBP - Tabaco",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      88,
      fullName: "LBP - Masbate",
      client_no: '10489',
      client_name: "LBP - Masbate",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      89,
      fullName: "LBP - Boac",
      client_no: '10490',
      client_name: "LBP - Boac",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      90,
      fullName: "LBP - Lucban",
      client_no: '10491',
      client_name: "LBP - Lucban",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      91,
      fullName: "LBP - Infanta",
      client_no: '10492',
      client_name: "LBP - Infanta",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      92,
      fullName: "LBP - Candelaria",
      client_no: '10493',
      client_name: "LBP - Candelaria",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      93,
      fullName: "LBP - Sta. Cruz Capitol",
      client_no: '10495',
      client_name: "LBP - Sta. Cruz Capitol",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      94,
      fullName: "LBP - Lucena",
      client_no: '10497',
      client_name: "LBP - Lucena",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      95,
      fullName: "LBP - San Pedro Laguna",
      client_no: '10499',
      client_name: "LBP - San Pedro Laguna",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      96,
      fullName: "LBP - Sta. Rosa Laguna",
      client_no: '10501',
      client_name: "LBP - Sta. Rosa Laguna",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      97,
      fullName: "LBP - Sta. Rosa Eo",
      client_no: '10502',
      client_name: "LBP - Sta. Rosa Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      98,
      fullName: "LBP - Cabuyao",
      client_no: '10503',
      client_name: "LBP - Cabuyao",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:      99,
      fullName: "LBP - Calamba",
      client_no: '10504',
      client_name: "LBP - Calamba",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     100,
      fullName: "LBP - Calamba EO",
      client_no: '10505',
      client_name: "LBP - Calamba EO",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     101,
      fullName: "LBP - San Pablo Laguna",
      client_no: '10506',
      client_name: "LBP - San Pablo Laguna",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     102,
      fullName: "LBP - Up Los Ba¤os",
      client_no: '10507',
      client_name: "LBP - Up Los Ba¤os",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     103,
      fullName: "LBP - Mulanay",
      client_no: '10510',
      client_name: "LBP - Mulanay",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     104,
      fullName: "LBP - YMCA Branch",
      client_no: '10511',
      client_name: "LBP - YMCA Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     105,
      fullName: "LBP - Supreme Court Branch",
      client_no: '10512',
      client_name: "LBP - Supreme Court Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     106,
      fullName: "LBP - Taft Ave. Branch",
      client_no: '10513',
      client_name: "LBP - Taft Ave. Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     107,
      fullName: "LBP - BOC MICP Tellering Booth",
      client_no: '10514',
      client_name: "LBP - BOC MICP Tellering Booth",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     108,
      fullName: "LBP - South Harbor Branch",
      client_no: '10515',
      client_name: "LBP - South Harbor Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     109,
      fullName: "LBP - G. Araneta Branch",
      client_no: '10516',
      client_name: "LBP - G. Araneta Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     110,
      fullName: "LBP - Espa¤a Branch",
      client_no: '10517',
      client_name: "LBP - Espa¤a Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     111,
      fullName: "LBP - Binondo Branch",
      client_no: '10518',
      client_name: "LBP - Binondo Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     112,
      fullName: "LBP - Coa Branch",
      client_no: '10521',
      client_name: "LBP - Coa Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     113,
      fullName: "LBP - Camp Crame Branch",
      client_no: '10522',
      client_name: "LBP - Camp Crame Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     114,
      fullName: "LBP - Malaca¤ang Branch",
      client_no: '10524',
      client_name: "LBP - Malaca¤ang Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     115,
      fullName: "LBP - Dole Eo",
      client_no: '10526',
      client_name: "LBP - Dole Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     116,
      fullName: "LBP - Intramuros Branch",
      client_no: '10527',
      client_name: "LBP - Intramuros Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     117,
      fullName: "LBP - Quezon City Hall Branch",
      client_no: '10529',
      client_name: "LBP - Quezon City Hall Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     118,
      fullName: "LBP - Quezon City Circle Branch",
      client_no: '10530',
      client_name: "LBP - Quezon City Circle Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     119,
      fullName: "LBP - Ombudsman Eo",
      client_no: '10531',
      client_name: "LBP - Ombudsman Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     120,
      fullName: "LBP - Lwua Eo",
      client_no: '10534',
      client_name: "LBP - Lwua Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     121,
      fullName: "LBP - Katipunan Branch",
      client_no: '10535',
      client_name: "LBP - Katipunan Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     122,
      fullName: "LBP - Eliptical Road Branch",
      client_no: '10536',
      client_name: "LBP - Eliptical Road Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     123,
      fullName: "LBP - Edsa - Nia Road Branch",
      client_no: '10537',
      client_name: "LBP - Edsa - Nia Road Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     124,
      fullName: "LBP - LTO Eo",
      client_no: '10539',
      client_name: "LBP - LTO Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     125,
      fullName: "LBP - East Ave. Branch",
      client_no: '10540',
      client_name: "LBP - East Ave. Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     126,
      fullName: "LBP - Araneta Center Eo",
      client_no: '10541',
      client_name: "LBP - Araneta Center Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     127,
      fullName: "LBP - Cubao Branch",
      client_no: '10542',
      client_name: "LBP - Cubao Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     128,
      fullName: "LBP - Valenzuela Branch",
      client_no: '10543',
      client_name: "LBP - Valenzuela Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     129,
      fullName: "LBP - Tayuman Branch",
      client_no: '10544',
      client_name: "LBP - Tayuman Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     130,
      fullName: "LBP - Quezon Ave. Branch",
      client_no: '10545',
      client_name: "LBP - Quezon Ave. Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     131,
      fullName: "LBP - Novaliches Branch",
      client_no: '10546',
      client_name: "LBP - Novaliches Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     132,
      fullName: "LBP - Navotas Branch",
      client_no: '10547',
      client_name: "LBP - Navotas Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     133,
      fullName: "LBP - Malabon Branch",
      client_no: '10548',
      client_name: "LBP - Malabon Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     134,
      fullName: "LBP - Caloocan Branch",
      client_no: '10549',
      client_name: "LBP - Caloocan Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     135,
      fullName: "LBP - Caloocan Eo",
      client_no: '10550',
      client_name: "LBP - Caloocan Eo",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     136,
      fullName: "LBP - Camp Aguinaldo Branch",
      client_no: '10552',
      client_name: "LBP - Camp Aguinaldo Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     137,
      fullName: "LBP - Antipolo Warehouse",
      client_no: '10555',
      client_name: "LBP - Antipolo Warehouse",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     138,
      fullName: "LBP - West Ave. (TMG) Warehouse",
      client_no: '10557',
      client_name: "LBP - West Ave. (TMG) Warehouse",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     139,
      fullName: "LBP - Plaza",
      client_no: '10558',
      client_name: "LBP - Plaza",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     140,
      fullName: "LBP - Overhead",
      client_no: '10559',
      client_name: "LBP - Overhead",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     141,
      fullName: "Toshiba - LTI Annex Internal",
      client_no: '10560',
      client_name: "Toshiba - LTI Annex Internal",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     142,
      fullName: "LBP - Augmentation",
      client_no: '10561',
      client_name: "LBP - Augmentation",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     143,
      fullName: "Toshiba Logistic",
      client_no: '10564',
      client_name: "Toshiba Logistic",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     144,
      fullName: "Isuzu Taytay Rizal",
      client_no: '10565',
      client_name: "Isuzu Taytay Rizal",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     145,
      fullName: "LBP CATAINGAN",
      client_no: '10566',
      client_name: "LBP CATAINGAN",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     146,
      fullName: "LBP SAN ANDRES",
      client_no: '10567',
      client_name: "LBP SAN ANDRES",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     147,
      fullName: "Lbp Catanauan",
      client_no: '10569',
      client_name: "Lbp Catanauan",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     148,
      fullName: "Lbp Tayuman Accounting Center",
      client_no: '10573',
      client_name: "Lbp Tayuman Accounting Center",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     149,
      fullName: "Lbp West Ave- Cash Dept.",
      client_no: '10575',
      client_name: "Lbp West Ave- Cash Dept.",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     150,
      fullName: "Lbp Lucena Accounting Center",
      client_no: '10577',
      client_name: "Lbp Lucena Accounting Center",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     151,
      fullName: "Bank of the Phil. Islands- Binondo Dasmari¤as Branch",
      client_no: '10581',
      client_name: "Bank of the Phil. Islands- Binondo Dasmari¤as Branch",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     152,
      fullName: "Maxus Showroom",
      client_no: '10582',
      client_name: "Maxus Showroom",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     153,
      fullName: "LBP-Alaminos Laguna",
      client_no: '10584',
      client_name: "LBP-Alaminos Laguna",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     154,
      fullName: "LBP-STA CRUZ BUBUKAL",
      client_no: '10585',
      client_name: "LBP-STA CRUZ BUBUKAL",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     155,
      fullName: "Kingston Excell",
      client_no: '10586',
      client_name: "Kingston Excell",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     156,
      fullName: "BPI PACO PEDRO GIL",
      client_no: '10587',
      client_name: "BPI PACO PEDRO GIL",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     157,
      fullName: "BPI PADRE FAURA",
      client_no: '10588',
      client_name: "BPI PADRE FAURA",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     158,
      fullName: "BPI U.N. AVE POPE PIOUS",
      client_no: '10589',
      client_name: "BPI U.N. AVE POPE PIOUS",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     159,
      fullName: "BPI U.N. AVE ERMITA",
      client_no: '10590',
      client_name: "BPI U.N. AVE ERMITA",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     160,
      fullName: "BPI TAFT AVENUE",
      client_no: '10591',
      client_name: "BPI TAFT AVENUE",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     161,
      fullName: "OFBANK",
      client_no: '10596',
      client_name: "OFBANK",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     162,
      fullName: "Pioneer Life (Hollard)",
      client_no: '10597',
      client_name: "Pioneer Life (Hollard)",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     163,
      fullName: "ISUZU BACOOR CAVITE",
      client_no: '10598',
      client_name: "ISUZU BACOOR CAVITE",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     164,
      fullName: "M PIONEER",
      client_no: '10599',
      client_name: "M PIONEER",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     165,
      fullName: "Lbp Calabanga Agri Hub",
      client_no: '10601',
      client_name: "Lbp Calabanga Agri Hub",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     166,
      fullName: "Lbp Pinamalayan Oriental Mindoro",
      client_no: '10603',
      client_name: "Lbp Pinamalayan Oriental Mindoro",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     167,
      fullName: "Lbp San Jose Occidental Mindoro",
      client_no: '10608',
      client_name: "Lbp San Jose Occidental Mindoro",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     168,
      fullName: "Lbp Sablayan Occidental Mindoro",
      client_no: '10609',
      client_name: "Lbp Sablayan Occidental Mindoro",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     169,
      fullName: "Lbp Batangas City",
      client_no: '10610',
      client_name: "Lbp Batangas City",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     170,
      fullName: "Lbp Lipa Batangas City",
      client_no: '10611',
      client_name: "Lbp Lipa Batangas City",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     171,
      fullName: "Lbp Tanauan Batangas",
      client_no: '10612',
      client_name: "Lbp Tanauan Batangas",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     172,
      fullName: "Lbp Nasugbu Batangas",
      client_no: '10613',
      client_name: "Lbp Nasugbu Batangas",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     173,
      fullName: "Lbp Lemery Batangas",
      client_no: '10614',
      client_name: "Lbp Lemery Batangas",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     174,
      fullName: "Lbp Santo Tomas Batangas",
      client_no: '10615',
      client_name: "Lbp Santo Tomas Batangas",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     175,
      fullName: "Lbp Rosario Batangas",
      client_no: '10616',
      client_name: "Lbp Rosario Batangas",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     176,
      fullName: "Lbp Metro Mart",
      client_no: '10621',
      client_name: "Lbp Metro Mart",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     177,
      fullName: "Lbp San Jose Lending Center",
      client_no: '10622',
      client_name: "Lbp San Jose Lending Center",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     178,
      fullName: "Bank of the Phil. Islands - LEON GUINTO BRANCH",
      client_no: '10623',
      client_name: "Bank of the Phil. Islands - LEON GUINTO BRANCH",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     179,
      fullName: "Lbp ROPA",
      client_no: '10624',
      client_name: "Lbp ROPA",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     180,
      fullName: "Lbp Sta. Cruz Bubukal Accounting Center",
      client_no: '10627',
      client_name: "Lbp Sta. Cruz Bubukal Accounting Center",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     181,
      fullName: "Bank of the Phil. Islands Escolta Ware House",
      client_no: '10629',
      client_name: "Bank of the Phil. Islands Escolta Ware House",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     182,
      fullName: "KIA AND MAXUS ALABANG",
      client_no: '10630',
      client_name: "KIA AND MAXUS ALABANG",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     183,
      fullName: "Lbp Real Quezon",
      client_no: '10631',
      client_name: "Lbp Real Quezon",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     184,
      fullName: "Lbp Labo Property",
      client_no: '10632',
      client_name: "Lbp Labo Property",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
    {
      id:     185,
      fullName: "OHGITANI",
      client_no: '10633',
      client_name: "OHGITANI",
      country: 'Philippines',
      contact: '',
      email: '',
      avatar: '',
      status: 'Active'
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/apps/client/clients').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    role = null,
    plan = null,
    status = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.clients.filter(
    client =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (client.client_no.toLowerCase().includes(queryLowered) || client.client_name.toLowerCase().includes(queryLowered)) &&
      client.role === (role || client.role) &&
      client.currentPlan === (plan || client.currentPlan) &&
      client.status === (status || client.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      clients: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new client
// ------------------------------------------------
mock.onPost('/apps/client/clients').reply(config => {
  // Get event from post data
  const { client } = JSON.parse(config.data)

  // Assign Status
  client.status = 'active'

  const { length } = data.clients
  let lastIndex = 0
  if (length) {
    lastIndex = data.clients[length - 1].id
  }
  client.id = lastIndex + 1

  data.clients.push(client)

  return [201, { client }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/client\/clients\/\d+/).reply(config => {
  // Get event id from URL
  let clientId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  clientId = Number(clientId)

  const clientIndex = data.clients.findIndex(e => e.id === clientId)
  const client = data.clients[clientIndex]

  if (client) return [200, client]
  return [404]
})
