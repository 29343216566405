export default [
  {
    path: "/access-control",
    name: "access-control",
    component: () => import("@/views/extensions/acl/AccessControl.vue"),
    meta: {
      resource: "ACL",
      action: "read",
    },
  },
  {
    path: "/apps-vale-list",
    name: "apps-vale-list",
    component: () => import("@/views/apps/vale/vale-list/ValeList.vue"),
    meta: {
      resource: "LENDING",
      action: "read",
    },
  },
  {
    path: "/apps/vale/preview/:id",
    name: "apps-vale-preview",
    component: () => import("@/views/apps/vale/vale-preview/ValePreview.vue"),
    meta: {
      resource: "LENDING",
      action: "read",
    },
  },
  {
    path: "/apps/vale/add/",
    name: "apps-vale-add",
    component: () => import("@/views/apps/vale/vale-add/ValeAdd.vue"),
    meta: {
      resource: "LENDING",
      action: "read",
    },
  },
  {
    path: "/apps/vale/edit/:id",
    name: "apps-vale-edit",
    component: () => import("@/views/apps/vale/vale-edit/ValeEdit.vue"),
    meta: {
      resource: "LENDING",
      action: "read",
    },
  },
];
